import React from 'react';

const Checkbox = ({label, value, setValue}) => {
    return <div className="form-check">
            <input className="form-check-input" type="checkbox" checked={value} onChange={setValue} />
                <label className="form-check-label">
                    {label}
                </label>
        </div>;
};

export default Checkbox;
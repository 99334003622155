import React from "react";
import Table from "./table/Table";
import TableHead from "./table/TableHead";
import PageLayout from "../../layout/page-layout/PageLayout";
import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";
import FlexBox from "./FlexBox";
import {ArrowBarDown, ArrowDown, ArrowDownShort} from "react-bootstrap-icons";
import DropdownToggle from "react-bootstrap/DropdownToggle";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error:"", fullError:{}};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
        this.setState({error:error.message, fullError:errorInfo})
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <TransparentPageLayout>
                    <FlexBox direction={"column"} style={{height:"70vh"}}>
                        <h2 style={{color: "#364454", fontWeight: "700"}}>Oops..</h2><br/>
                        <h2 style={{color: "#364454", fontWeight: "700"}}>Something went wrong while loading the page...</h2><br/>
                        <h3 style={{color: "#364454", fontWeight: "700"}}>Please try again, or contact us at info@lugo-test.com for help.</h3><br/>
                        <br/>
                        <span>More Details <ArrowDownShort /></span>
                        {this.state.error + JSON.stringify(this.state.fullError)}
                    </FlexBox>
                </TransparentPageLayout>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary
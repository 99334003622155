import React, {useEffect, useState} from 'react';
import './ItemsList.css';
import InputText from "../InputText";
import {Dash, Plus, PlusCircle} from "react-bootstrap-icons";
import checkEmail from "../../../pages/user/login-register/Utilities";
import {CustomErrorToast} from "../CustomToast";

const ItemsList = ({listData = [], setListData, label}) => {
    const [inputValue, setInputValue] = useState('');

    const addItem = () => {
        if (!inputValue) return;
        if (listData.includes(inputValue)) {
            setInputValue('');
            return;
        }
        if (!checkEmail(inputValue)) {
            CustomErrorToast("Invalid Email")
            setInputValue("")
            return;
        }
        setListData([...listData, inputValue]);
        setInputValue('');
    };

    const removeItem = (val) => {
        setListData(listData.filter((e) => e !== val));
    };

    useEffect(()=>{
        function handleClick(e){
            if (document.getElementsByClassName('listWrapper')[0].contains(e.target) || e.target.id == 'removeItem'){
                // Clicked in box
            } else{
                document.getElementById('listData').style.display = 'none'
            }
        }

        window.addEventListener('click', handleClick);

        return ()=> window.removeEventListener('click', handleClick);

    },[])

    return (<div>

            <div className="listWrapper">
                <div id="listInputWrapper">

                    <InputText
                        divWidth={"100%"}
                        width={"220px"}
                        marginRight={0}
                        field={inputValue}
                        setField={(e) => setInputValue(e.target.value)}
                        type={"text"}
                        className={"header-field"}
                        placeHolder={listData.length === 0 ? 'Empty' : `${listData.length} ${listData.length > 1 ? 'Items' : 'Item'}`}
                        onFocus={() => document.getElementById('listData').style.display = 'block'}
                        onKeyDown={(e) => e.key === 'Enter' && addItem()}/>
                    <Plus id={"addItem"} onClick={addItem}/>
                </div>
                <ul id="listData" style={{display: 'none'}}>
                    {listData.length === 0 ? (<li style={{color: '#ccc'}}>Empty</li>) : (listData.map((n, index) => {
                        const shortened = n.length > 21 ? `${n.substring(0, 21)}...` : n;
                        return (<li className="listItem" style={{color: '#000'}} key={index}>
                            <span style={{maxWidth:"70%", fontSize:"14px"}} title={n}>{shortened}</span>
                            <Dash id={"removeItem"} onClick={()=>removeItem(n)} />
                        </li>);
                    }))}
                </ul>
            </div>

        </div>);
};

export default ItemsList;
import React from 'react';
import FlexBox from "./FlexBox";
import {Button} from "react-bootstrap";

const ConfirmModal = ({show, setShow, title, body, callback, fallback=()=>setShow(false), no="No", yes="Yes", variant="primary"}) => {
    return (
        <FlexBox hidden={!show} style={{background:"rgba(0,0,0,.3)",width:"100vw", height:"100vh", position:"fixed", top:0, left:0, zIndex:12}}>
            <FlexBox direction={"column"} style={{textAlign:"center",width:"450px", height:"max-content", background:"white", border:"1px solid #ebebeb", padding:"25px"}} className={"hard-shadow"}>
                <h3>{title}</h3>
                <p>{body}</p>
                <FlexBox direction={"row-reverse"} style={{width:"max-content"}} justify={"space-between"}>
                    <Button  variant={"secondary"} style={{width:"100px"}} onClick={fallback}>{no}</Button>
                    &nbsp;&nbsp;
                    <Button variant={variant} style={{width:"100px"}} onClick={callback}>{yes}</Button>
                </FlexBox>
            </FlexBox>
        </FlexBox>
    )
};

export default ConfirmModal;
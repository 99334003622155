import React from 'react';
import FlexBox from "../FlexBox";
import {X} from "react-bootstrap-icons";

const ModalHeader = ({title, setShow, icon=""}) => {
    return (
        <div style={{
            textAlign: "left", width: "100%", height: "80px", background: "#4760F1", position: "relative"
        }}>
            <FlexBox justify={"start"} align={"center"} style={{
                lineHeight: "20px",
                color: "#fff",
                fontWeight: "800",
                fontSize: "20px",
                height: "100%",
                padding: "20px"
            }}>{icon}{icon ? <span>&nbsp;</span> : ""}<span>{title}</span>
                <X size={"25px"} onClick={(e) => setShow(false)}
                   style={{position: "absolute", right: 15, top: 15, cursor: "pointer"}}></X>
            </FlexBox>
        </div>
    )
};

export default ModalHeader;
export const SVGBilling = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.25 1.25634C14.1881 1.25213 14.1258 1.25 14.0633 1.25H8C5.37665 1.25 3.25 3.37665 3.25 6V18C3.25 20.6234 5.37665 22.75 8 22.75H16C18.6234 22.75 20.75 20.6234 20.75 18V8.75H17C15.4812 8.75 14.25 7.51878 14.25 6V1.25634ZM7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12ZM8 16.25C7.58579 16.25 7.25 16.5858 7.25 17C7.25 17.4142 7.58579 17.75 8 17.75H12C12.4142 17.75 12.75 17.4142 12.75 17C12.75 16.5858 12.4142 16.25 12 16.25H8Z"
                  fill={color}/>
            <path
                d="M20.3215 7.25C20.2584 7.15059 20.1887 7.0549 20.1126 6.96359L16.1759 2.23949C16.048 2.08601 15.905 1.94837 15.75 1.82802V6C15.75 6.69036 16.3096 7.25 17 7.25H20.3215Z"
                fill={color}/>
        </svg>

    )
}

export const SVGSettings = ({color = "#BCB8C6"}) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={color}
                 className="bi bi-gear-wide-connected" viewBox="0 0 16 16">
            <path
                d="M7.068.727c.243-.97 1.62-.97 1.864 0l.071.286a.96.96 0 0 0 1.622.434l.205-.211c.695-.719 1.888-.03 1.613.931l-.08.284a.96.96 0 0 0 1.187 1.187l.283-.081c.96-.275 1.65.918.931 1.613l-.211.205a.96.96 0 0 0 .434 1.622l.286.071c.97.243.97 1.62 0 1.864l-.286.071a.96.96 0 0 0-.434 1.622l.211.205c.719.695.03 1.888-.931 1.613l-.284-.08a.96.96 0 0 0-1.187 1.187l.081.283c.275.96-.918 1.65-1.613.931l-.205-.211a.96.96 0 0 0-1.622.434l-.071.286c-.243.97-1.62.97-1.864 0l-.071-.286a.96.96 0 0 0-1.622-.434l-.205.211c-.695.719-1.888.03-1.613-.931l.08-.284a.96.96 0 0 0-1.186-1.187l-.284.081c-.96.275-1.65-.918-.931-1.613l.211-.205a.96.96 0 0 0-.434-1.622l-.286-.071c-.97-.243-.97-1.62 0-1.864l.286-.071a.96.96 0 0 0 .434-1.622l-.211-.205c-.719-.695-.03-1.888.931-1.613l.284.08a.96.96 0 0 0 1.187-1.186l-.081-.284c-.275-.96.918-1.65 1.613-.931l.205.211a.96.96 0 0 0 1.622-.434l.071-.286zM12.973 8.5H8.25l-2.834 3.779A4.998 4.998 0 0 0 12.973 8.5zm0-1a4.998 4.998 0 0 0-7.557-3.779l2.834 3.78h4.723zM5.048 3.967c-.03.021-.058.043-.087.065l.087-.065zm-.431.355A4.984 4.984 0 0 0 3.002 8c0 1.455.622 2.765 1.615 3.678L7.375 8 4.617 4.322zm.344 7.646.087.065-.087-.065z"/>
        </svg>

    )
}



export const SVGCandidatesList = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M20.307 18.9194C20.3614 18.9266 20.4159 18.934 20.4703 18.9417L20.7438 18.9801C21.9346 19.1472 23 18.2395 23 17.0579V16.1562C23 15.3111 22.4592 14.5573 21.649 14.2731C20.7129 13.9447 19.7466 13.7309 18.7711 13.6316C19.789 14.3675 20.4203 15.5592 20.4203 16.8594V17.979C20.4203 18.3041 20.3809 18.619 20.307 18.9194ZM16.1812 12.432C16.5868 12.5995 17.0324 12.692 17.5 12.692C19.3813 12.692 20.9064 11.1939 20.9064 9.34597C20.9064 7.49801 19.3813 5.99994 17.5 5.99994C17.4443 5.99994 17.3889 6.00125 17.3338 6.00385C17.6759 6.73246 17.8671 7.546 17.8671 8.40419C17.8671 9.9807 17.2219 11.4065 16.1812 12.432Z"
                  fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M4.07387 18.9194C4.01942 18.9266 3.96499 18.934 3.91058 18.9417L3.63707 18.9801C2.44625 19.1472 1.38084 18.2395 1.38084 17.0579V16.1562C1.38084 15.3111 1.92166 14.5573 2.73185 14.2731C3.66791 13.9447 4.63427 13.7309 5.60979 13.6316C4.59191 14.3675 3.96057 15.5592 3.96057 16.8594V17.979C3.96057 18.3041 3.99992 18.619 4.07387 18.9194ZM8.19971 12.432C7.79406 12.5995 7.34845 12.692 6.88084 12.692C4.99952 12.692 3.47442 11.1939 3.47442 9.34597C3.47442 7.49801 4.99952 5.99994 6.88084 5.99994C6.93657 5.99994 6.992 6.00125 7.04707 6.00385C6.70496 6.73246 6.51377 7.546 6.51377 8.40419C6.51377 9.9807 7.15896 11.4065 8.19971 12.432Z"
                  fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16.3671 8.40419C16.3671 6.10986 14.5071 4.24994 12.2128 4.24994C9.91848 4.24994 8.05856 6.10986 8.05856 8.40419C8.05856 10.6985 9.91848 12.5584 12.2128 12.5584C14.5071 12.5584 16.3671 10.6985 16.3671 8.40419Z"
                  fill={color}/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.15298 14.5214C10.425 13.3528 14.0006 13.3528 17.2727 14.5214C18.2607 14.8743 18.9203 15.8102 18.9203 16.8594V17.979C18.9203 19.4459 17.621 20.5728 16.1687 20.3654L15.8351 20.3177C13.4325 19.9745 10.9932 19.9745 8.59049 20.3177L8.25693 20.3654C6.80468 20.5728 5.50537 19.4459 5.50537 17.979V16.8594C5.50537 15.8102 6.16492 14.8743 7.15298 14.5214Z"
                  fill={color}/>
        </svg>

    )
}

export const SVGDashboard = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="7" height="4" rx="2" fill={color} stroke={color} stroke-width="1.5"/>
            <rect x="4" y="11" width="7" height="10" rx="2.5" fill={color} stroke={color} stroke-width="1.5"/>
            <rect x="14" y="4" width="7" height="10" rx="2.5" fill={color} stroke={color} stroke-width="1.5"/>
            <rect x="14" y="17" width="7" height="4" rx="2" fill={color} stroke={color} stroke-width="1.5"/>
        </svg>

    )
}

export const SVGInvoices = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3 2.75C2.58579 2.75 2.25 3.08579 2.25 3.5C2.25 3.91421 2.58579 4.25 3 4.25H4.25V19.4656C4.25 20.9186 5.91841 21.7387 7.06891 20.8512L8.39974 19.8246C8.48344 19.76 8.59871 19.7551 8.68763 19.8122L10.5129 20.9856C11.4188 21.568 12.5812 21.568 13.4871 20.9856L15.3124 19.8122C15.4013 19.7551 15.5166 19.76 15.6003 19.8246L16.9311 20.8512C18.0816 21.7387 19.75 20.9186 19.75 19.4656V4.25H21C21.4142 4.25 21.75 3.91421 21.75 3.5C21.75 3.08579 21.4142 2.75 21 2.75H3ZM8.25 9.5C8.25 9.08579 8.58579 8.75 9 8.75H12C12.4142 8.75 12.75 9.08579 12.75 9.5C12.75 9.91421 12.4142 10.25 12 10.25H9C8.58579 10.25 8.25 9.91421 8.25 9.5ZM9 12.75C8.58579 12.75 8.25 13.0858 8.25 13.5C8.25 13.9142 8.58579 14.25 9 14.25H12C12.4142 14.25 12.75 13.9142 12.75 13.5C12.75 13.0858 12.4142 12.75 12 12.75H9ZM14.25 9.5C14.25 9.08579 14.5858 8.75 15 8.75H15.5C15.9142 8.75 16.25 9.08579 16.25 9.5C16.25 9.91421 15.9142 10.25 15.5 10.25H15C14.5858 10.25 14.25 9.91421 14.25 9.5ZM15 12.75C14.5858 12.75 14.25 13.0858 14.25 13.5C14.25 13.9142 14.5858 14.25 15 14.25H15.5C15.9142 14.25 16.25 13.9142 16.25 13.5C16.25 13.0858 15.9142 12.75 15.5 12.75H15Z"
                  fill={color}/>
        </svg>

    )
}

export const SVGNewCandidate = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle r="4" transform="matrix(-1 0 0 1 10 7)" fill={color} stroke={color} stroke-width="1.5"/>
            <path
                d="M3 16.9347C3 16.0743 3.54085 15.3068 4.35109 15.0175V15.0175C8.00404 13.7128 11.996 13.7128 15.6489 15.0175V15.0175C16.4591 15.3068 17 16.0743 17 16.9347V18.2502C17 19.4376 15.9483 20.3498 14.7728 20.1818L13.8184 20.0455C11.2856 19.6837 8.71435 19.6837 6.18162 20.0455L5.22721 20.1818C4.0517 20.3498 3 19.4376 3 18.2502V16.9347Z"
                fill={color} stroke={color} stroke-width="1.5"/>
            <path d="M17 11H21" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19 9L19 13" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    )
}

export const SVGPricing = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.50199 6.25C6.12128 6.25 4.1083 8.01239 3.79366 10.3722L2.86033 17.3722C2.48065 20.2198 4.69591 22.75 7.56866 22.75H16.4312C19.3039 22.75 21.5192 20.2198 21.1395 17.3722L20.2062 10.3722C19.8915 8.01239 17.8786 6.25 15.4979 6.25H8.50199ZM7.25 6C7.25 3.37665 9.37665 1.25 12 1.25C14.6234 1.25 16.75 3.37665 16.75 6V9C16.75 9.41421 16.4142 9.75 16 9.75C15.5858 9.75 15.25 9.41421 15.25 9V6C15.25 4.20507 13.7949 2.75 12 2.75C10.2051 2.75 8.75 4.20507 8.75 6V9C8.75 9.41421 8.41421 9.75 8 9.75C7.58579 9.75 7.25 9.41421 7.25 9V6Z"
                  fill={color}/>
        </svg>

    )
}

export const SVGSupport = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 19V12C21 7.02944 16.9706 3 12 3V3C7.02944 3 3 7.02944 3 12V19" stroke={color}
                  stroke-width="1.5" stroke-linecap="round"/>
            <path
                d="M16 15C16 13.8954 16.8954 13 18 13H19C20.1046 13 21 13.8954 21 15V19C21 20.1046 20.1046 21 19 21H18C16.8954 21 16 20.1046 16 19V15Z"
                fill={color} stroke={color} stroke-width="1.5"/>
            <path
                d="M8 15C8 13.8954 7.10457 13 6 13H5C3.89543 13 3 13.8954 3 15V19C3 20.1046 3.89543 21 5 21H6C7.10457 21 8 20.1046 8 19V15Z"
                fill={color} stroke={color} stroke-width="1.5"/>
        </svg>

    )
}

export const SVGTestsList = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.6198 1.93606C12.9917 1.02131 11.0083 1.02131 9.38018 1.93606L3.34356 5.32762C1.88548 6.14682 1.88548 8.25428 3.34356 9.07348L9.38018 12.465C11.0083 13.3798 12.9917 13.3798 14.6198 12.465L20.6564 9.07348C22.1145 8.25428 22.1145 6.14682 20.6564 5.32762L14.6198 1.93606Z"
                fill={color}/>
            <path
                d="M2.97517 15.337C1.89805 16.277 2.02084 18.0803 3.34356 18.8235L9.38018 22.215C11.0083 23.1298 12.9917 23.1298 14.6198 22.215L20.6564 18.8235C21.9792 18.0803 22.102 16.277 21.0248 15.337L15.3545 18.5228C13.2701 19.6939 10.7299 19.6939 8.64545 18.5228L2.97517 15.337Z"
                fill={color}/>
            <path
                d="M2.81218 10.4955C1.91468 11.4674 2.0918 13.1202 3.34356 13.8235L9.38018 17.215C11.0083 18.1298 12.9917 18.1298 14.6198 17.215L20.6564 13.8235C21.9082 13.1202 22.0853 11.4674 21.1878 10.4955L15.3545 13.7728C13.2701 14.9439 10.7299 14.9439 8.64545 13.7728L2.81218 10.4955Z"
                fill={color}/>
        </svg>

    )
}

export const SVGTestsStatus = ({color = "#BCB8C6"}) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M1.25 7C1.25 3.82436 3.82436 1.25 7 1.25H17C20.1756 1.25 22.75 3.82436 22.75 7V17C22.75 20.1756 20.1756 22.75 17 22.75H7C3.82436 22.75 1.25 20.1756 1.25 17V7ZM7.25 17C7.25 17.4142 7.58579 17.75 8 17.75C8.41421 17.75 8.75 17.4142 8.75 17V14C8.75 13.5858 8.41421 13.25 8 13.25C7.58579 13.25 7.25 13.5858 7.25 14V17ZM12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V7C11.25 6.58579 11.5858 6.25 12 6.25C12.4142 6.25 12.75 6.58579 12.75 7V17C12.75 17.4142 12.4142 17.75 12 17.75ZM15.25 17C15.25 17.4142 15.5858 17.75 16 17.75C16.4142 17.75 16.75 17.4142 16.75 17V10C16.75 9.58579 16.4142 9.25 16 9.25C15.5858 9.25 15.25 9.58579 15.25 10V17Z"
                  fill={color}/>
        </svg>

    )
}

import React from 'react';
import {Info, InfoCircle, InfoCircleFill} from "react-bootstrap-icons";
import ReactTooltip from 'react-tooltip';

const Tooltip = ({text,style={}}, size="") => {
    return (
        <>
            <ReactTooltip className={"tooltip"} multiline={true} place="top" type="dark" effect="solid"/>
            <InfoCircleFill fill={"rgb(71, 96, 241)"} data-tooltip-place="top" data-tip={text} style={style} ></InfoCircleFill>


        </>

    )
};



export default Tooltip;
import './PageLayout.css'

const TransparentPageLayout = ({children, style={}})=>{

    return (
        <div className={"container transparent-container"}>
            <div style={style} className={"content"}>
                {children}
            </div>
        </div>
    )
}

export default TransparentPageLayout;
import './Status.css'

const Status = ({status,style, label}) => {

    switch (status) {
        case "failed":
            return <Failed style={style} label={label} />
        case "passed":
            return <Passed style={style} label={label} />
        case "pending":
            return <Pending style={style} label={label} />
    }
}

export default Status

export const Pending = ({style={},label="PENDING"}) => {
    return (<div style={style} className={"status pending"}>
        {label}
    </div>)
}
export const Passed = ({style={},label="PASSED"}) => {
    return (<div style={style} className={"status passed"}>
        {label}
    </div>)
}
export const Failed = ({style={},label="FAILED"}) => {
    return (<div style={style} className={"status failed"}>
        {label}
    </div>)
}
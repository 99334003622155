import './UsersManagement.css'

import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";
import FlexBox from "../../components/utilities/FlexBox";


import {useContext, useEffect, useMemo, useRef, useState} from "react";

import companyTeamContext from "../../CompanyTeamContext";
import {
    ANNUAL_TIMEFRAME,
    LIVE_CLIENT_ID,
} from "../../utilities/Constants";
import {
    CustomErrorToast,
    CustomLoadingToast, CustomSuccessToast, CustomToastContainer, updateCustomErrorToast, updateCustomSuccessToast
} from "../../components/utilities/CustomToast";
import Table from "../../components/utilities/table/Table";
import TableHead from "../../components/utilities/table/TableHead";
import TableBody from "../../components/utilities/table/TableBody";
import InputText, {InputSelect} from "../../components/utilities/InputText";
import {Button} from "react-bootstrap";
import checkEmail from "./login-register/Utilities";
import axios from "axios";
import Loader from "../../components/utilities/Loader";
import confirmModal from "../../components/utilities/ConfirmModal";
import ConfirmModal from "../../components/utilities/ConfirmModal";


const QUIZ_PRICE = 17;
const initialOptions = {
    "client-id": LIVE_CLIENT_ID, currency: "USD", vault: true, intent: "subscription", locale: "en_US"
}

const UsersManagement = () => {
    const company = useContext(companyTeamContext) || {}
    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState()
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        if(company){
            document.title  = "Users Management - " + company.basicTitle
            refreshUsers();
            return () => {
                document.title = company.basicTitle
            }

        }
    },[company])

    const refreshUsers = ()=>{
        // Get the company users
        axios.get("/GetAllCompanyUsers.html")
            .then((res)=>res.data)
            .then(data => {
                if(data){
                    setUsers(data)
                    setLoader(false)
                }
                else {
                    CustomErrorToast("Error Fetching Users")
                }
            })
            .catch(e=> {
                CustomErrorToast("Error Fetching Users")
            })
    }

    return (<TransparentPageLayout>
            <Loader loader={loader}/>
            <FlexBox style={{height: "100%", width: "100%"}} align={"start"} direction={"column"}>
                <Header/>
                <FlexBox style={{width: "100%", marginRight: "25px", height: "100%", flexWrap:"wrap"}} direction={"row"} justify={"space-between"} align={"start"}>
                    <UsersTable setSelectedUser={setSelectedUser} users={users} refreshUsers={refreshUsers} />
                    <AddUser users={users} refreshUsers={refreshUsers} setSelectedUser={setSelectedUser} selectedUser={selectedUser} company={company} />
                </FlexBox>
            </FlexBox>
    </TransparentPageLayout>)

}
export default UsersManagement

function Header() {
    return (<FlexBox className={"container soft-shadow"} style={{
        width: "100%", marginBottom: "25px", background: "#fff"
    }}>
        <FlexBox direction={"row"} style={{width: "100%", maxWidth: "1000px", padding:"15px 15px"}} justify={"space-between"} align={"center"}>
            <h3 style={{fontWeight: "bold", margin:0}}>Company Users Management</h3>
        </FlexBox>
    </FlexBox>);
}



function AddUser({refreshUsers, selectedUser, setSelectedUser, users, company}) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [position, setPosition] = useState("")
    const [email, setEmail] = useState("")
    const [role, setRole] = useState(0)

    useEffect(()=>{
        if(!selectedUser){
            resetFields();
        }
        else{
            let user = users.find(u=>u.key == selectedUser);
            setFirstName(user.firstName)
            setLastName(user.lastName)
            setPosition(user.position?.replaceAll("&amp;","&"))
            setEmail(user.email)
            setRole(user.role)
        }
    },[selectedUser])

    const resetFields = ()=> {
        setFirstName("")
        setLastName("")
        setPosition("")
        setEmail("")
        setRole(0)
    }

    const addUser = () =>{
        if(firstName.length < 2 || lastName.length < 2 || position.length < 2 || email.length < 2){
            CustomErrorToast("Please fill all fields");
            return
        }
        if(!checkEmail(email)){
            CustomErrorToast("Invalid Email");
            return
        }
        const id = CustomLoadingToast("Adding...")
        axios.post("/AddUser.html", {firstName, lastName, position, role, email, key:selectedUser})
            .then((res) => res.data)
            .then((data) => {
                if(data.result === true){
                    updateCustomSuccessToast( selectedUser ? "Saved!" : "Added!", id)
                    setSelectedUser(false)
                    refreshUsers();
                }
                else{
                    if(data.info){
                        updateCustomErrorToast(data.info, id)
                    }
                    else{
                        updateCustomErrorToast("Error Adding User", id)
                    }

                }
            })
            .catch(e => {
                updateCustomErrorToast("Error Adding User", id)
            })
    }

    return (<div className={"container soft-shadow"}
                 style={{ width: "calc(40% - 25px)", margin:0, padding: "20px", background: "#fff", }}>


        <FlexBox style={{marginBottom: "10px"}} justify={"space-between"} align={"start"} direction={"column"}>
            <div style={{
                lineHeight: "20px", color: "#979DA5", fontWeight: "800", fontSize: "20px", marginBottom:"15px"
            }}>{selectedUser ? "Edit User" :  "Invite User"}
            </div>
            <div style={{width:"100%"}}>
                <InputText label={"First Name"} required={true} id={"fName"} field={firstName} setField={e => setFirstName(e.target.value)}/>
                <InputText label={"Last Name"} required={true} id={"lName"} field={lastName} setField={e => setLastName(e.target.value)}/>
                <InputText label={"Position"} required={true} id={"position"} field={position} setField={e => setPosition(e.target.value)}/>
                <InputText disabled={!!selectedUser} label={"Email"} required={true} id={"email"} field={email} setField={e => setEmail(e.target.value)}/>
                <InputSelect disabled={company?.loggedLiteUser?.key == selectedUser} label={"Role"} field={role} setField={(e)=> setRole(e.target.value)} >
                    <option value={1}>Admin</option>
                    <option value={0}>User</option>
                </InputSelect>
                <br/>
                <FlexBox justify={"space-between"}>
                    <Button hidden={!selectedUser} onClick={()=>setSelectedUser(null)}
                            style={{float: "right", width: "120px", borderRadius: "2px", fontSize: "14px"}}
                            variant={"secondary"}>Cancel</Button>

                    <Button id={"saveUser"} onClick={addUser}
                            style={{float: "right", width: "120px", borderRadius: "2px", fontSize: "14px"}}
                            variant={"primary"}>{selectedUser ? "Save" : "Add"} User</Button>
                </FlexBox>

            </div>
        </FlexBox>

    </div>);
}

function UserRow({user, setSelectedUser, refreshUsers}){
    const company = useContext(companyTeamContext);
    const [showConfirm, setShowConfirm] = useState(false)

    const deleteUserAction = () =>{
        const id = CustomLoadingToast("Deleting...")
        axios.post("/DeleteUser.html?id="+user.key)
            .then(res => res.data)
            .then(data => {
                if(data.result === true){
                    updateCustomSuccessToast("Deleted!", id)
                    setShowConfirm(false)
                    refreshUsers();
                }
                else {
                    updateCustomErrorToast("Error deleting user!", id)
                }
            })
            .catch(e => {
                updateCustomErrorToast("Error deleting user!", id)
            })
    }

    return (<tr>
        <ConfirmModal show={showConfirm} setShow={setShowConfirm} body={"This operation is permanent"} variant={"danger"} title={"Delete User"} callback={deleteUserAction} />
        <td>{user.firstName + " " + user.lastName}</td>
        <td>{user.position?.replaceAll("&amp;","&")}</td>
        <td style={{textAlign:"right"}}>
            <Button onClick={() => setSelectedUser(user.key)} variant={"success"}>Edit</Button>&nbsp;&nbsp;
            <Button hidden={company?.loggedLiteUser?.key == user.key} onClick={() => setShowConfirm(true)} variant={"danger"}>Delete</Button>
        </td>
    </tr>)
}

function UsersTable({users, setSelectedUser, refreshUsers}) {
    return (<div className={"container soft-shadow"}
                 style={{
                     width: "60%", margin:0, padding: "20px", background: "#fff",
                 }}>


        <FlexBox style={{marginBottom: "10px"}} justify={"space-between"} align={"start"} direction={"column"}>
            <div style={{
                lineHeight: "20px", color: "#979DA5", fontWeight: "800", fontSize: "20px", marginBottom:"15px"
            }}>Users List

            </div>
            <div style={{width:"100%"}}>
                <Table style={{width:"100%"}}>
                    <TableHead columns={["Name", "Position", ""]} />
                    <TableBody>
                        {users?.map(u=>{
                            return <UserRow user={u} setSelectedUser={setSelectedUser} refreshUsers={refreshUsers} />
                        })}

                    </TableBody>
                </Table>
            </div>

        </FlexBox>

    </div>);
}

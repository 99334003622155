import './Table.css'

const Table = ({children, style={}, id})=>{
    if(id){
        return (
            <table id={id} className={"table"} style={{border: "1px solid rgba(239, 239, 239, 1)",...style}}>
                {children}
            </table>
        )
    }

    return (
        <table className={"table"} style={{border: "1px solid rgba(239, 239, 239, 1)",...style}}>
            {children}
        </table>
    )
}
export default Table
import React from 'react';
import {ClipLoader} from "react-spinners";

const CSSProperties = {
    position: "absolute", left: "calc(50% - 35px)", filter: "drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25))", top: "50%",zIndex:20
}

const Loader = ({style = {}, loader}) => {
    return (<ClipLoader color={"#bababa"} loading={loader} cssOverride={{...CSSProperties,...style}} size={70}/>)
}

export default Loader;
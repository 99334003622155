import React from 'react';

import "./RadioOption.css"

const RadioOptions = ({options, value, setValue, background="white", color="#DCDBDB", width="", keys=["RAD1","RAD2"],disabled=false}) => {
    if(!keys){
        keys = new Array(options.length).fill("RADIOKEY")
    }
    return (
        <div className={"toggle-type"} style={{padding: "2px", margin: "2px", width:width}}>
            {options.map((option,i)=>{
                return (
                    <button style={{margin:"0px 5px"}} hidden={disabled && option.value != value} key={keys[i]} onClick={e=>setValue(option.value)}
                        className={"btn toggle-option" + (value == option.value ?" toggle-option-active" : "")+ (disabled ?" toggle-option-disabled" : "")} style={{background:background, color:color}}>{option.name}
                    </button>
                )
            })}
        </div>
    )
};

export default RadioOptions;
import './Charts.css'

import React, {useContext, useEffect, useRef} from 'react';
import PageLayout from "../../layout/page-layout/PageLayout";
import {useNavigate, useParams} from "react-router-dom";
import FlexBox from "../../components/utilities/FlexBox";
import {convertQueryStringToObject} from "../../utilities/Utilities";
import CanvasJSReact from '@canvasjs/react-charts';
import ReactDOM from "react-dom";

import JustGauge from 'justgage';
import CompanyTeamContext from "../../CompanyTeamContext";
import companyTeamContext from "../../CompanyTeamContext";
import {Button} from "react-bootstrap";

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

let correctCount = 0;
let totalQCount = 0;
var enhanced;

function Header({testName, candidateName}) {
    return <FlexBox className={"container"} align={"center"} justify={"start"} style={{
        background: "#4760F1", height: "80px", padding: "20px", boxSizing: "border-box"
    }}>
        <FlexBox justify={"space-between"}>
            <div style={{
                lineHeight: "20px", color: "#fff", fontWeight: "800", fontSize: "20px"
            }}> {`${testName} Test Results of ${candidateName}`}
            </div>
        </FlexBox>
    </FlexBox>;
}

const Charts = ({}) => {
    const {results} = useParams();

    const data = convertQueryStringToObject(results)

    const company = useContext(companyTeamContext)
    const navigate = useNavigate()
    useEffect(() => {
        if(company){
            document.title  = "Test Charts - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])

    return (
        <PageLayout height={"100%"} padding={"0px"}>
            <Button onClick={()=> navigate(-1)} variant={"outline-light"} style={{position:"absolute", top:15,right:15, zIndex:6}}>Back</Button>
            <span style={{}}>
            <Header candidateName={data.cand} testName={data.quizName}/>
            <div style={{
                justifyContent: "top",
                display: "flex",
                height: "calc(100% - 80px)",
                width: "100%",
                flexDirection: "column",
                padding: "30px"
            }}>
                <div style={{width:"100%"}}>
                    <p style={{fontWeight:"bold"}}>Topics Review</p>
                    <TopicsReview data={data}/>
                </div>
                <FlexBox className={"wrapper"} justify={"space-between"} style={{width:"100%", padding:"10px"}}>
                    <ScoreReview data={data} />
                    <TimeReview data={data} />
                </FlexBox>

            </div>
            </span>
        </PageLayout>)
};

const TopicsReview = ({data}) => {

    var dataPointsArr = initQuizResultsDetails(data.results)

    CanvasJS.addColorSet("successColorSet",
        [//colorSet Array
            "#d65b4a",
            "#da932c",
            "#66b92e"
        ]);



    CanvasJS.addColorSet("timeColorSet",
        [//colorSet Array
            "#d65b4a",
            "transparent"
        ]);

    let options = {
        title:{
            text: ""
        },
        colorSet: "topicsColorSet",
        animationEnabled: true,
        axisX:{interval: 1} ,
        panEnabled: true,

        toolTip:{
            shared: true,
        },
        axisY:{
            minimum: 0,
            gridThickness: .0,
            maximum: 1,
            valueFormatString:"#%"
        },
        data: [
            {
                type: "bar",
                dataPoints: dataPointsArr
            }

        ]
    }

    if(dataPointsArr && dataPointsArr.length == 1){
        options = {
            colorSet: "topicsColorSet",
            title:{
                text: ""
            },
            toolTip:{
                shared: true,
            },
            axisY:{
                minimum: 0,
                gridThickness: .2,
                maximum: 1,
                valueFormatString:"#%"
            },
            data: [
                {
                    type: "bar",
                    dataPoints: []
                }
            ]
        }
        options.data[0].dataPoints = dataPointsArr
    }

    return (
        <div style={{width:"100%"}}>
            <CanvasJSChart containerProps={{height:"350px"}} options = {options} />
        </div>
    )
}

const ScoreReview = ({data}) => {
    let cColor;
    const {score} = data
    if(score > 0 && score <= 50){
        cColor ="#d65b4a"
    }
    else if( score > 50 && score <= 80){
        cColor="#da932c"
    }
    else{
        cColor="#66b92e"
    }

    let options = {
        animationDuration: 800,
        animationEnabled: true,
        width:270,
        backgroundColor: "transparent",
        theme: "theme2",
        title: {
            fontSize: 55,
            fontFamily:"Segoe UI",
            verticalAlign: "center",
            text: (""+Math.floor(data.score || 0)).trim()
        },
        subtitles:[
        ],
        data: [
            {
                innerRadius: "70%",
                radius: "90%",
                legendMarkerType: "square",
                showInLegend: false,
                startAngle: 90,
                type: "doughnut",
                dataPoints: [
                    {  y: score, name: "Weak" ,color:cColor},
                    {  y: 100-score, name: "Good" ,color:"lightgray"},

                ]
                //toolTipContent: "<span style='\"'color: ;'\"'>{name}</span>: <b>{y}</b>"
            }
        ],
        toolTip:{
            enabled: false,
        }
    }

    let scoreStats = 'NA';
    if(score>=0){
        if(score<50){
            scoreStats = 'Weak';
        }else if(score>=50 && score <80){
            scoreStats = 'Good';
        }else{
            scoreStats = 'Excellent';
        }
    }


    return (
        <div style={{width:"100%", borderRight:"1px solid #ccc"}}>

            <FlexBox justify={"space-between"} style={{width:"100%", padding:"10px"}}>
                <CanvasJSChart containerProps={{height:"270px", width:"270px"}} options = {options} />
                <FlexBox direction={"column"} align={"start"} style={{width: "calc(100% - 270px)", height:"100%"}}>
                    <div style={{width:"310px", marginBottom:"15px"}}>{data.cand} has taken the <b style={{display:"inline"}}>{data.quizName}</b> Test and got <b>{Math.floor(data.score || 0)}</b> out of <b>100 points</b>.</div>
                    <div style={{"padding":"15px","background":"rgba(245,183,1,0.1)","color":"#CD7700","border":"1px solid #F5B701","fontWeight":"200", width:"310px"}}>
                        This score considered to be {scoreStats}.
                    </div>



                </FlexBox>
            </FlexBox>

        </div>
    )
}

const TimeReview = ({data}) => {
    const {totalTime, candidateTime, cand, quizName} = data
    let lblt = ' Test';
    if(quizName.toLowerCase().indexOf("test")!=-1){
        lblt = '';
    }
    let candidateTimeStr;
    let totalTimeStr;
    if(candidateTime==1){
        candidateTimeStr = "1 min";
    }else if(candidateTime>=0){
        candidateTimeStr = candidateTime+" min";
    }else{
        candidateTimeStr = "NA";
    }
    if (totalTime > 0){
        totalTimeStr = totalTime +" min";
    }else{
        totalTimeStr = "0";
    }
    let addition = <b>This test had no time limit</b>
    if(parseFloat(totalTime)>0){
        addition = <div>This test had a time limitation of <b>{totalTime} minutes.</b></div>;
    }
    let all = <div>{cand} has finished the <b>{quizName}</b>{lblt} in <b>{candidateTime} minutes</b>.<br/><br/>{addition}</div>;

    console.log(totalTimeStr)
    return (
        <FlexBox justify={"space-between"} align={"center"} style={{ width:"100%", padding:"10px"}}>
            {totalTimeStr != "0" &&  <Gauge candidateTimeStr={candidateTime} totalTimeStr={totalTime} />}
            <div style={{width:"40%"}}>{all}</div>
        </FlexBox>
    )
}

const Gauge = ({candidateTimeStr, totalTimeStr}) => {
    const gaugeRef = useRef(null); // To hold our gauge element
    let gaugeInstance = null;     // Will store the JustGauge instance

    useEffect(() => {
        // Create a new JustGauge instance on component mount
        gaugeInstance = new JustGauge({
            id: gaugeRef.current.id,
            titleFontFamily: "Segoe UI",
            valueFontFamily:  "Segoe UI",

            labelMinFontSize:"20px",
            value: candidateTimeStr,
            min: 0,
            max: totalTimeStr,
            label: "minutes",
            levelColors: [
                "#66b92e",
                "#da932c",
                "#d65b4a"
            ],
            gaugeWidthScale: 1,
            valueFontColor: "#292b2c",
            labelFontColor: "#595c5e",
            pointer: true,
            pointerOptions: {
                toplength: -15,
                bottomlength: 10,
                bottomwidth: 6,
                color: '#595c5e',
                stroke: '#ffffff',
                stroke_width: 2,
                stroke_linecap: 'round'
            }
        });

        return () => {
            // Optional: Clean up on component unmount
            gaugeInstance = null;
        };
    }, []);

    useEffect(() => {
        // Update JustGauge value if the props.value changes
        if (gaugeInstance) {
            gaugeInstance.refresh(candidateTimeStr);
        }
    }, [candidateTimeStr, totalTimeStr]);

    return <div style={{width:"60%"}} id={`gauge`} ref={gaugeRef}></div>;
};

function initQuizResultsDetails(results) {
    var quizResultsList = new Array();
    var arrColors = new Array();

    if(results.length == 0)return;
    var classifyNames = new Array();
    var arr = results.split('_QQQ_');
    var summarySF = new Object();
    //if(arr.length > 10){
    var newArray  = "";
    for (var z = 0 ; z< arr.length ; z++){
        var line = arr[z];
        var cls = line.split('->');
        var pos = 0;
        var areaName;
        if(cls.length==5){
            areaName = cls[pos++];
        }
        var categoryName = cls[pos++];
        var versionName = cls[pos++];
        var topicName = cls[pos++];
        var difficulty;
        try{
            difficulty = cls[pos].charAt(0);
        }catch(err){
            console.log(1);
        }
        var orgCatName = categoryName;
        if(categoryName=='Custom'){
            categoryName = topicName;
        }
        if(areaName){
            if(areaName == 'Coding'){
                categoryName = categoryName +' (Coding)';
            }
        }
        if(enhanced && enhanced=='true'){
            topicName = topicName.split('+').join(' ');
            topicName = topicName.split(',').join(' ');
            if(orgCatName!='Custom'){
                categoryName = categoryName+' '+versionName+' - '+topicName;
            }
        }
        if(summarySF[categoryName]==null){
            var successFail = new Object();
            successFail.Failures = 0;
            successFail.Successes = 0;
            summarySF[categoryName] = successFail;
        }

        //Duplicate Code
        var  success = line.split('_success_');
        var successNumber = 0;
        if(success.length > 1){
            var tmp = success[1];
            if(tmp.length > 1){
                if(!isNaN(parseInt(tmp.charAt(1)))){
                    successNumber = parseInt(tmp.charAt(0)+tmp.charAt(1));
                }else{
                    successNumber = parseInt(tmp.charAt(0));
                }
            }else{
                successNumber = parseInt(tmp.charAt(0));
            }
        }
        summarySF[categoryName].Successes += successNumber;
        totalQCount += successNumber;
        correctCount += successNumber;
        var  failures = line.split('_failed_');
        var failuresNumber = 0;
        if(failures.length > 1){
            var tmp = failures[1];
            if(tmp.length > 1){
                if(!isNaN(parseInt(tmp.charAt(1)))){
                    failuresNumber = parseInt(tmp.charAt(0)+tmp.charAt(1))
                }else{
                    failuresNumber = parseInt(tmp.charAt(0));
                }
            }else{
                failuresNumber = parseInt(tmp.charAt(0));
            }
        }
        summarySF[categoryName].Failures += failuresNumber;
        totalQCount += failuresNumber;
        //Duplicate Code Ends
    }
    var counter = 0;
    for( var keyCatName in summarySF){
        var newItem = new Object();
        //keyCatName = keyCatName.replace("Sharp","#");
        newItem.label= keyCatName
        newItem.x = counter++;
        var clsName = keyCatName;
        if(clsName.indexOf("Custom Custom ")!=-1){
            clsName = clsName.substr("Custom Custom ".length);
        }
        clsName = clsName.trim();
        if (clsName.length > 45){
            newItem.TopicName = "..."+clsName.substr(clsName.length-45,clsName.length);
        } else {
            newItem.TopicName = clsName;
        }
        if(areaName){
            if(areaName == 'Coding'){
                newItem.TopicName = newItem.TopicName+ '(Coding)';
            }
        }
        //newItem.Difficulty = 'Senior';
        newItem.Successes = summarySF[keyCatName].Successes;
        newItem.Failures = summarySF[keyCatName].Failures;
        if(newItem.Failures == 0){
            newItem.y = 1;
            arrColors.push("#66b92e");
        }else if(newItem.Successes == 0){
            newItem.y = 0;
            arrColors.push("#d65b4a");
        }else {
            newItem.y = ((newItem.Successes/(newItem.Successes+newItem.Failures)));
            if(newItem.y<.5){
                arrColors.push("#d65b4a");
            }else if(newItem.y>=.5 && newItem.y<.8){
                arrColors.push("#da932c");
            }else {
                arrColors.push("#66b92e");
            }
        }
        if(keyCatName && keyCatName.indexOf('Coding')!=-1){
            newItem.toolTipContent = "<b>Correct Test Cases Amount:</b> "+newItem.Successes+" out of "+(newItem.Failures+newItem.Successes) ; //<br><b>Difficuly:</b> "+newItem.Difficulty;
        }else{
            newItem.toolTipContent = "<b>Correct Questions Amount:</b> "+newItem.Successes+" out of "+(newItem.Failures+newItem.Successes) ; //<br><b>Difficuly:</b> "+newItem.Difficulty;
        }
        quizResultsList.push(newItem);
    }
    CanvasJS.addColorSet("topicsColorSet",arrColors);
    return quizResultsList;

}

export default Charts;
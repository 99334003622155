import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import IFrameWrapper from "../../components/IFrameWrapper";
import {IFRAME_DOMAIN} from "../../utilities/Constants";
import {Button} from "react-bootstrap";
import FlexBox from "../../components/utilities/FlexBox";
import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";

const AdvancedCharts = (props) => {
    const {results} = useParams();
    const navigation = useNavigate();
    return (<TransparentPageLayout>
            <FlexBox style={{width:"100%", margin:"0px 12px"}} justify={"space-between"}>
                <h3 style={{fontWeight:"bold"}}>Test Charts</h3>
                <div><Button onClick={e=>navigation(-1)} variant={"outline-success"}>Back to Candidate</Button></div>
            </FlexBox>
            <IFrameWrapper src={IFRAME_DOMAIN+"/advancedCharts.html"+results} />
        </TransparentPageLayout>
    )
};

export default AdvancedCharts;
import './ModalBackground.css'

import React from 'react';
import FlexBox from "../FlexBox";

const ModalBackground = ({children, style={}, align="center", justify="center"}) => {
    return (
        <FlexBox className={"modal-background"} align={align} justify={justify} style={style}>
            {children}
        </FlexBox>
    )
};

export default ModalBackground;
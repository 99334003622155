import './TestsList.css'
import TableHead from "../../../components/utilities/table/TableHead";
import PageLayout from "../../../layout/page-layout/PageLayout";
import Table from "../../../components/utilities/table/Table";
import TableBody from "../../../components/utilities/table/TableBody";
import {useEffect, useState, useContext, useRef} from "react";
import TableRow from "../../../components/utilities/table/TableRow";
import {Button} from "react-bootstrap";
import FlexBox from "../../../components/utilities/FlexBox";

import SearchBar from "../../../components/search/SearchBar";
import axios from "axios";
import Loader from "../../../components/utilities/Loader";
import {APP_URL_PREFIX} from "../../../utilities//Constants";
import {getCandidateFullName} from "../../../utilities/Utilities";
import {
    CustomErrorToast,
    CustomLoadingToast,
    CustomToastContainer, DismissToast, updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import {sendTestToCandidate, sendTestToNewCandidate} from "../../../components/send-test/SendTest";
import {ArrowDown, ArrowDownShort, ArrowUpShort, CaretDown, CaretUp, X} from "react-bootstrap-icons";
import ConfirmModal from "../../../components/utilities/ConfirmModal";
import CompanyTeamContext from "../../../CompanyTeamContext";
import Tooltip from "../../../components/utilities/Tooltip";
import {Link} from "react-router-dom";
import {API_URL_PREFIX, TEST_BUILDER_PRIVILEGE} from "../../../utilities/Constants";
import {QuestionsExplorer} from "../test-new-view/TestBuilder";

const TestsList = () => {
    const [tests, setTests] = useState([])
    const [loader, setLoader] = useState(true)
    const [candidates, setCandidates] = useState()
    const [keyword, setKeyword] = useState("")
    const [previewTest, setPreviewTest] = useState({})
    const [showPreview, setShowPreview] = useState(false)
    const company = useContext(CompanyTeamContext)

    useEffect(
        () => {
            if(!candidates) {
                refresh()
            }
        }
        ,[tests])

    const refresh = () =>{
        axios.get(API_URL_PREFIX+"/GetAllCandidates.html")
            .then(e=>{
                let data = e.data;
                if(data && !data.err){
                    setCandidates(e.data)
                }
            })
            .catch(err=>{
                console.log(err)
            })
    }

    useEffect(() => {
        if(company){
            document.title  = "Tests List - " + company.basicTitle
        }
        axios.get(API_URL_PREFIX+"/GetCompanyLiteTests.html")
            .then(response => {
                setLoader(false)
                if (response.data.err) {
                    CustomErrorToast("Permission Denied!")
                } else {
                    setTests(response.data.sort(  (a,b) =>{
                            if(a.predefinedTest == b.predefinedTest){
                                return b.lastModified - a.lastModified;
                            }
                            else{
                                return a.predefinedTest - b.predefinedTest
                            }
                    }  ))
                }
            })
            .catch(e => {
                setLoader(false);
                if (e.response.status == 403) {
                    CustomErrorToast("Permission Denied!")
                } else {
                    CustomErrorToast("Error fetching tests, Please try again.")
                }

            })
        return () => {
            if(company){
                document.title = company.basicTitle
            }
        }
    }, [company])

    const getPreviewTest = (testId)=>{
        const id = CustomLoadingToast("Loading...")
        axios.get(API_URL_PREFIX+"/GetCompanyLiteTestPreview.html?id="+testId)
        .then(e=>{
            let data = e.data;
            if(data && !data.err){
                setPreviewTest(data)
                setShowPreview(true)
                DismissToast(id);
            }
            else{
                if(data.info){
                    updateCustomErrorToast(data.info,id)
                }
                else{
                    updateCustomErrorToast("Preview Error.",id)
                }
            }
        })
        .catch(err=>{
            updateCustomErrorToast("Preview Error.",id)
        })
    }

    let filteredList = tests && tests.filter(t=>t.name.toLowerCase().includes(keyword.toLowerCase()))

    return (<PageLayout  height={"100%"}>
        {showPreview && previewTest && previewTest.quizQuestions && <QuestionsExplorer  testTime={previewTest.timePerQuiz} testName={previewTest.name} isViewOnly={true} questions={previewTest.quizQuestions} setLoader={false} setQuestions={()=>{}} setShowQuestionsExplorer={setShowPreview} /> }

        <Loader loader={loader}/>
        <div style={{justifyContent: "top", display: "flex",height:"100%", width: "100%", flexDirection: "column", padding: "30px"}}>
            <FlexBox style={{width:"100%"}} justify={"space-between"}>
                <h3 style={{color: "#364454", fontWeight: "700", fontSize: "20px"}}>Tests List</h3>
                <SearchBar justify={"end"} value={keyword} setValue={e=>setKeyword(e.target.value)} placeHolder={"Search by Test Name"}/>
            </FlexBox>
            <br/>
            <div className={"table-wrapper"}>
                <Table id={"testsTable"}>
                    <TableHead columns={["", "Test Name", "Duration", "Questions", "Last Modified", "", "", ""]}/>
                    <TableBody>
                        { !loader && (!tests || filteredList.length == 0)
                            && <tr><td style={{verticalAlign:"middle", color:"#bababa", textAlign:"center"}} rowSpan={5} colSpan={6}>No Tests Found.</td></tr> }
                        {filteredList.map((test, i) => {
                            return (<TestRow refresh={refresh} getPreviewTest={getPreviewTest} setTests={setTests} candidates={candidates} setCandidates={setCandidates} i={i} test={test}/>)
                        })}
                    </TableBody>
                </Table>
            </div>
            <div style={{marginTop:"10px", fontSize:"12px", textAlign:"left"}}>{!filteredList ? 0 : filteredList.length} Tests</div>
        </div>
    </PageLayout>)
}

function TestRow({setTests, test,candidates,i, getPreviewTest, setCandidates, refresh}) {
    const [show, setShow] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete ] = useState(false)
    const [privileges, setPrivileges] = useState([])
    const company = useContext(CompanyTeamContext)

    useEffect(()=>{
        if(company && company.privileges){
            setPrivileges(company.privileges);
        }
    },[company])


    const deleteTest = ()=>{

        const id = CustomLoadingToast("Deleting...")
        axios.delete(API_URL_PREFIX+"/DeleteTest.html?id="+test.key)
            .then(e=>{
                let data = e.data;
                if(data && !data.err){
                    setTests(tests=>tests.filter(e=>e.key!=test.key))
                    updateCustomSuccessToast("Deleted",id)
                }
                else{
                    updateCustomErrorToast(data.info,id)
                }
                setShowConfirmDelete(false)
            })
            .catch(err=>{
                setShowConfirmDelete(false)
                updateCustomErrorToast("Error, Please try again",id)
                console.log(err)
            })
    }
    if(test.predefinedTest){
        return <TableRow>
            <td style={{padding: "15px", textAlign: "center"}}>{+i + 1}</td>
            <td style={{fontWeight:"bold"}}>{test.name}</td>

            <td>{test.timePerQuiz}</td>
            <td style={{textAlign: "left", paddingLeft: "35px"}}>{test.amountOfQuestions}</td>
            <td>{new Date(test.lastModified).toDateString()}</td>
            <td>
            <span style={{position: "relative"}}>
                <Button onClick={event => setShow(!show)} style={{fontWeight: "700", position: "relative"}}
                        variant={"outline-primary"}>Send to</Button>
                <SendTo refresh={refresh} setShow={setShow} show={show} test={test} candidates={candidates} setCandidates={setCandidates}/>
            </span>
            </td>
            <td >
                <FlexBox style={{cursor: "pointer", position: "relative", fontWeight:"bold"}} justify={"center"} align={"center"}>
                    <Tooltip text={(test.predefinedTestDescription ? test.predefinedTestDescription.value : "") || "Predefined tests are tests created by LugoLite with diverse questions and topics for instant use."}></Tooltip>
                    &nbsp;Description
                </FlexBox>
            </td>
            <td><Button onClick={e=>getPreviewTest(test.key)}>{privileges.includes(TEST_BUILDER_PRIVILEGE) ? "Preview" : "Example Question" }</Button></td>
        </TableRow>
    }
    return <TableRow>
        <td style={{padding: "15px", textAlign: "center"}}>{+i + 1}</td>
        {privileges.includes(TEST_BUILDER_PRIVILEGE) ?

            <td><Link className={"candidate-link"} to={APP_URL_PREFIX + "/test/" + test.key}>{test.name}</Link></td>
        :
            <td style={{fontWeight:"bold"}}>{test.name}</td>
        }

        <td>{test.timePerQuiz}</td>
        <td style={{textAlign: "left", paddingLeft: "35px"}}>{test.amountOfQuestions}</td>
        <td>{new Date(test.lastModified).toDateString()}</td>
        <td>
            <span style={{position: "relative"}}>
                <Button onClick={event => setShow(!show)} style={{fontWeight: "700", position: "relative"}}
                        variant={"outline-primary"}>Send to</Button>
                <SendTo refresh={refresh} setShow={setShow} show={show} test={test} candidates={candidates} setCandidates={setCandidates}/>
            </span>
        </td>
        <td style={{fontWeight:"bold", textAlign:"center"}}>
            &nbsp;Custom Test
        </td>
        <td>
            <FlexBox style={{cursor: "pointer", position: "relative"}} justify={"center"} align={"center"}>
                <Button onClick={() => setShowConfirmDelete(true)} variant={"outline-danger"}><X/> Delete</Button>
            </FlexBox>

        </td>
        {showConfirmDelete
            && <ConfirmModal variant={"danger"} show={showConfirmDelete} setShow={setShowConfirmDelete}
                             callback={deleteTest} body={"Are you sure you want to delete "+test.name+" test?"}
                             title={"Confirm Delete"} yes={"Delete"} no={"Cancel"}
                             fallback={e=>setShowConfirmDelete(false)}  />}
    </TableRow>;
}

const SendTo = ({test, candidates, setCandidates, show, setShow, refresh}) => {
    const [keyword, setKeyword] = useState("")
    const [sentStatus, setSentStatus] = useState({})

    const company = useContext(CompanyTeamContext) || {}
    let refreshQuota = company.refresh
    const ref = useRef();

    const mouseOutHandle = e=>{
        setShow(show =>{
            if(show){
                if(ref.current && !ref.current.contains(e.target)){
                    return false;
                }
            }
            return show;
        })
    }

    useEffect(()=>{
        document.addEventListener('mousedown',mouseOutHandle)
        return (()=>{
            document.removeEventListener('mousedown',mouseOutHandle)
        })
    },[])

    return (<FlexBox refVar={ref} hidden={!show} direction={"column"} justify={"start"} align={"start"}
                     className={"soft-shadow " + (show ? "transformToShow" : "transformToHide")}
                     style={{
                         position: "absolute",
                         top: "38px",
                         right: "83px",
                         background: "#fff",
                         width: "280px",
                         height:"max-content",
                         padding: "15px",
                         zIndex:5000
                     }}>
        <p style={{fontSize: "15px", fontWeight:"bold"}}>Send {test.name} Test to:</p>
        <SearchBar value={keyword} setValue={e=>setKeyword(e.target.value)} placeHolder={"Search Candidate"} width={"100%"}/>
        <div style={{minHeight:"100px",maxHeight:"350px", overflow:"auto", width:"100%"}}>
        {candidates && candidates.filter(c=>(getCandidateFullName(c).toLowerCase()).includes(keyword.toLowerCase()))
            .map(c=>{
                return  <FlexBox style={{width: "100%", padding: "10px 0px", paddingRight:"2px"}} justify={"space-between"}>
                    <div>{getCandidateFullName(c)}</div>
                    {(sentStatus && sentStatus[c.key])
                        ? <Button disabled={true} style={{height: "30px", padding: "2px 7px"}} variant={"outline-primary"}>Sent</Button>
                        : <Button onClick={e=>sendTestToCandidate(test.key, c,null,null,setSentStatus, refreshQuota)} style={{height: "30px", padding: "2px 5px"}} variant={"primary"}>Send</Button>}

                </FlexBox>
            })}
        </div>
        <NewCandidate refresh={refresh} test={test} setShow={setShow} refreshQuota={refreshQuota} />

        <FlexBox style={{width:"100%"}}>
            <Tooltip style={{width:"40px"}} text={"Unfinished or Disqualified Tests Will be Refunded to The Sent Tests Quota."} />
        </FlexBox>

    </FlexBox>)
}

const NewCandidate = ({test, setShow, refreshQuota, refresh}) =>{
    const [showNewCandidate, setShowNewCandidate] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    return (
        <FlexBox direction={"column"} style={{ width:"100%", marginBottom:"10px"}}>
            <Button onClick={e=>setShowNewCandidate(!showNewCandidate)} variant={"link"} style={{height: "30px",fontWeight:"bold", padding: "2px 7px", color:"#000",textDecoration:"none"}}>
                New Candidate {showNewCandidate ? <CaretUp /> : <CaretDown />}</Button>
            <FlexBox hidden={!showNewCandidate} direction={"column"} align={"start"}>
                Name: <input className={"form-control"} value={name} onChange={e=>setName(e.target.value)}/>
                Email: <input className={"form-control"} value={email} onChange={e=>setEmail(e.target.value)}/>
                <br/>
                <div style={{width:"100%",textAlign:"center"}}>
                    <Button onClick={e=>sendTestToNewCandidate(test.key,{name:name, email: email}, setShow, e=>{
                        setShowNewCandidate(false)
                        setName("")
                        setEmail("")
                        if(refresh) refresh()
                    }, null, refreshQuota)} style={{height: "42px",width:"70px", padding: "2px 5px"}} variant={"primary"}>Send</Button>
                </div>
            </FlexBox>
        </FlexBox>
    )
}

export default TestsList
import './QuizzesReport.css'
import TableHead from "../../../components/utilities/table/TableHead";
import PageLayout from "../../../layout/page-layout/PageLayout";
import Table from "../../../components/utilities/table/Table";
import TableBody from "../../../components/utilities/table/TableBody";
import {forwardRef, useContext, useEffect, useMemo, useState} from "react";
import FlexBox from "../../../components/utilities/FlexBox";
import Loader from "../../../components/utilities/Loader";
import {
    CustomErrorToast, CustomInfoToast,
    CustomToastContainer, updateCustomErrorToast, updateCustomSuccessToast,
} from "../../../components/utilities/CustomToast";
import CompanyTeamContext from "../../../CompanyTeamContext";
import {useParams} from "react-router-dom";
import Tooltip from "../../../components/utilities/Tooltip";
import {IframeModal, QuizRow} from "../quiz/QuizzesList";


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Button} from "react-bootstrap";
import InputText from "../../../components/utilities/InputText";
import axios from "axios";
import {API_URL_PREFIX} from "../../../utilities/Constants";
import tr from "react-datepicker";
import * as XLSX from 'xlsx';
import xlsIcon from '../../../assets/xls.png'; // Adjust the path as necessary


const QuizzesReport = () => {
    const [quizzes, setQuizzes] = useState([])
    const [loader, setLoader] = useState(false)
    const [showIframe, setShowIframe] = useState(false)
    const [iframeSrc, setIframeSrc] = useState("")
    const company = useContext(CompanyTeamContext)
    const [date, setDate] = useState(new Date())

    useEffect(()=>{
        if(company){
            document.title  = "Tests Status - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])

    const generateReport = () => {
        if(!date){
            CustomErrorToast("Please fill the month/year")
            return;
        }
        setLoader(true)
        axios.get(API_URL_PREFIX+"/GetCompanyLiteQuizzesReport.html?date="+date.getTime())
            .then(response => {
                setLoader(false)
                if (response.data.err) {
                    CustomErrorToast("Permission Denied!")
                } else {
                    if(response.data.length == 0){
                        CustomInfoToast("No Tests were found at the given month")
                    }
                    setQuizzes(response.data)
                }
            })
            .catch(e => {
                setLoader(false);
                if (e.response.status == 403) {
                    CustomErrorToast("Permission Denied!")
                } else {
                    CustomErrorToast("Error fetching report, Please try again.")
                }
            })
    }


    const filteredList = quizzes

    const exportToExcel = (quizzesData, fileName) => {
        const dataToExport = quizzesData.map(quiz => ({
        	"Candidate Name": quiz.candidateName,
        	"Test Name": quiz.testName,
        	"Score": quiz.quizScore,        	
        	"Quiz Date": new Date(quiz.quizDate)                         
        }));

        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Quizzes");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
    };


    return (<PageLayout height={"100%"}>
        <CustomToastContainer />
        {showIframe && <IframeModal setShow={setShowIframe} src={iframeSrc} />}
        <Loader loader={loader}/>
        <div style={{justifyContent: "top", display: "flex",height:"100%", width: "100%", flexDirection: "column", padding: "30px"}}>
            <FlexBox style={{width: "100%"}} justify={"space-between"}>
                <h3 style={{color: "#364454", fontWeight: "700", fontSize: "20px"}}>Tests Reports</h3>
                <FlexBox style={{gap:"10px"}}><label>Select Month: &nbsp;</label><DatePicker
                    showIcon
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    selected={date}
                    onChange={setDate}
                    className="form-control"
                />
                <Button onClick={generateReport}>Generate Report</Button></FlexBox>
                
                <button onClick={() => exportToExcel(quizzes, "QuizzesReport")} style={{ border: 'none', background: 'none' }}>
                <img src={xlsIcon} alt="Export to Excel" title="Export to Excel" style={{ width: '30px', height: '30px' }} />
              </button>


	

            </FlexBox>
            <br/>
            <FlexBox justify={"space-between"} align={"center"} style={{gap:"15px"}}>

            </FlexBox>
            <br/>
            <div hidden={quizzes.length == 0} className={"table-wrapper"}>
                <Table id={"quizzesTable"}>
                    <TableHead columns={["", "Assigned to", "Test Name", "Last Modified",
                        <FlexBox justify={"start"} style={{height:"100%"}}>Score&nbsp;<Tooltip text={"Score out of 100"} /></FlexBox>,
                        <FlexBox justify={"start"} style={{height:"100%"}}>Status&nbsp;<Tooltip text={"Pass score set at 60%"} /></FlexBox>, "Approval Date","Supervised", "",""]}/>
                    <TableBody>
                        {!loader && (!filteredList || filteredList.length == 0)
                            && <tr>
                                <td style={{verticalAlign: "middle", color: "#bababa", textAlign: "center"}} rowSpan={5}
                                    colSpan={6}>No Tests Found.
                                </td>
                            </tr>}
                        {filteredList && filteredList.map((quiz, i) => {
                            return (<QuizRow showApprovalDate={true} setShowIframe={setShowIframe} setIframeSrc={setIframeSrc} setQuizzes={setQuizzes} i={i} quiz={quiz}/>)
                        })}
                    </TableBody>
                </Table>

            </div>
            <div style={{marginTop:"10px", fontSize:"12px", textAlign:"left"}}>{!filteredList ? 0 : filteredList.length} Tests</div>
        </div>
    </PageLayout>)
}

export default QuizzesReport
import React, {useContext, useEffect} from 'react';
import {useParams} from "react-router-dom";
import IFrameWrapper from "../../components/IFrameWrapper";
import {IFRAME_DOMAIN} from "../../utilities/Constants";
import CompanyTeamContext from "../../CompanyTeamContext";
import {Button} from "react-bootstrap";

const TestReview = (props) => {
    const {testId} = useParams();
    const company = useContext(CompanyTeamContext)

    useEffect(() => {
        if(company){
            document.title  = "Test Review - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])


    return (
        <>
            <IFrameWrapper src={IFRAME_DOMAIN+"/TestReview.htm?candidateAnswers=All&liteColor=1&externalQuizId="+testId} />
        </>
    )
};

export default TestReview;
import {useContext, useEffect, useRef, useState} from "react";
import axios from "axios";
import {
    CustomErrorToast,
    CustomLoadingToast,
    CustomSuccessToast, CustomToastContainer, updateCustomErrorToast,
    updateCustomSuccessToast
} from "../utilities/CustomToast";
import FlexBox from "../utilities/FlexBox";
import Loader from "../utilities/Loader";
import {InfoCircle, InfoCircleFill, X} from "react-bootstrap-icons";
import {Button} from "react-bootstrap";
import CompanyTeamContext from "../../CompanyTeamContext";
import {API_URL_PREFIX} from "../../utilities/Constants";
import checkEmail from "../../pages/user/login-register/Utilities";
import ModalBackground from "../utilities/modal/ModalBackground";
import ModalHeader from "../utilities/modal/ModalHeader";
import DOMPurify from "dompurify";
import companyTeamContext from "../../CompanyTeamContext";

export const sendTestToCandidate = (selectedTest, candidate, setShow, refresh, setSentStatus, refreshQuota, setSubmitted = ()=>{})=>{
    if(!selectedTest || selectedTest == "dis" || !candidate) {
        CustomErrorToast("Select Test");
        return;
    }
    setSubmitted(true)
    const id = CustomLoadingToast("Sending...")
    axios.post(API_URL_PREFIX+"/SendQuizToCandidate.html?candId="+candidate.key+"&testId="+selectedTest)
        .then(e=>{
            if(e.data.result){
                updateCustomSuccessToast("Test sent to candidate.",id);
                //setSubmitted(false)
                if(setShow) setShow(false);
                if(refreshQuota) refreshQuota();
                if(refresh) refresh();
                if(setSentStatus){
                    setSentStatus(prev => {
                        if(!prev) return {[candidate.key]:true}
                        return {...prev, [candidate.key]:true}
                    })
                }
            }
            else{
                setSubmitted(false)
                if(e.data.info)
                    updateCustomErrorToast(e.data.info, id)
                else{
                    updateCustomErrorToast("Error adding test", id)
                }
            }
        })
        .catch(e=>{
            setSubmitted(false)
            updateCustomErrorToast("Error adding test", id);
        })
}

export const sendTestToNewCandidate = (selectedTest, candidate, setShow, refresh, setSentStatus, refreshQuota)=>{
    if(!selectedTest || selectedTest == "dis" || !candidate) {
        CustomErrorToast("Select Test");
        return;
    }

    if(!candidate.name) {
        CustomErrorToast("Fill candidate name");
        return;
    }

    if(!candidate.email || !checkEmail(candidate.email)) {
        CustomErrorToast("Invalid email");
        return;
    }

    const id = CustomLoadingToast("Sending...")
    axios.post(API_URL_PREFIX+"/SendQuizToNewCandidate.html?candName="+candidate.name+"&candEmail="+candidate.email+"&testId="+selectedTest)
        .then(e=>{
            if(e.data.result){
                updateCustomSuccessToast("Test sent to candidate.",id);
                if(setShow) setShow(false);
                if(refreshQuota) refreshQuota();
                if(refresh) refresh();
            }
            else{
                if(e.data.info)
                    updateCustomErrorToast(e.data.info, id)
                else{
                    updateCustomErrorToast("Error adding test", id)
                }
            }
        })
        .catch(e=>{
            updateCustomErrorToast("Error adding test", id);
        })
}

export const SendTestsCSV = ({setShow, refreshCandidates = ()=>{}}) => {
    const [tests, setTests] = useState([])
    const [loader, setLoader] = useState(true)
    const [selectedTest, setSelectedTest] = useState("dis")
    const [csvData, setCsvData] = useState()
    const [submitted, setSubmitted] = useState(false)
    const [fileName, setFileName] = useState("")
    const {refresh} = useContext(companyTeamContext)
    const ref = useRef()

    useEffect(()=>{
        axios.get(API_URL_PREFIX+"/GetCompanyLiteTests.html")
            .then(response => {
                setLoader(false)
                if (response.data.err) {
                    CustomErrorToast("Permission Denied!")
                }
                else{


                    if(response.data){
                        let predefined = response.data.filter(e=>e.predefinedTest).sort((b,a) => {
                            return (b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                        })
                        let org = response.data.filter(e=>!(e.predefinedTest)).sort((b,a) => {
                            return (b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                        })
                        let tests = [...org, ...predefined]
                        setTests(tests);

                        if(tests.length > 0){
                            setSelectedTest(tests[0].key)
                        }
                    }


                }
            })
            .catch(e => {
                setLoader(false);
                if(e.response?.status == 403){
                    CustomErrorToast("Permission Denied!")
                }
                else{
                    CustomErrorToast("Error fetching tests, Please try again.")
                }
            })
    },[])

    const sendTestInvitations = () =>{
        const formData = new FormData();
        formData.append('file', csvData);
        if(!csvData){
            CustomErrorToast("No CSV were uploaded.")
        }
        if(!selectedTest){
            CustomErrorToast("No test was selected.")
        }

        setSubmitted(true)
        const id = CustomLoadingToast("Processing...")
        axios.post('/SendQuizzedCSV.html?testId='+encodeURIComponent(selectedTest), formData)
            .then(res=>{
                setSubmitted(false)
                let data = res.data
                console.log(data);
                if(typeof data == 'string'){
                    data = JSON.parse(data)
                }
                if(data.result === true){
                    updateCustomSuccessToast(data.info+" tests were sent successfully!", id)
                    if(refresh){
                        refresh();
                    }
                    refreshCandidates();
                    setShow(false)
                }
                else{
                    if(data.err === true){
                        if(data.info){
                            updateCustomErrorToast(data.info,id)
                        }
                        else{
                            updateCustomErrorToast("Error! Please verify that the CSV file is valid and try again.",id)
                        }
                    }
                }
            })
            .catch(e=>{
                setSubmitted(false)
                updateCustomErrorToast("Error! Please verify that the CSV file is valid and try again.",id)
            })
    }

    return (
        <ModalBackground style={{position:"fixed"}}>
            <Loader loader={loader}/>
            <FlexBox direction={"column"} direction={"column"} justify={"start"} align={"start"}
                     style={{width: "650px", height:"maxContent", background: "white"}}>
                <ModalHeader title={"Send Tests - CSV"} setShow={setShow} />
                <FlexBox direction={"column"} justify={"start"} style={{padding:"15px", width:"100%"}}>


                    <div style={{display:"flex", justifyContent:"start", alignItems:"center", width:"100%"}}>
                        <div style={{width:"114px"}}>Select Test:&nbsp;</div>
                        {tests && tests.length > 0 ?
                            <select value={selectedTest} onChange={e=>setSelectedTest(e.target.value)} className={"form-select"}>
                                {tests.map(e=><option key={e.key+"SendTestsCSV"} value={e.key}>
                                    {e.predefinedTest ?  e.name + " [Predefined]" : e.name}
                                </option>)}
                            </select> : <p>No Tests Found</p>}
                    </div>
                    <br/>
                    <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                        <input hidden={true}
                            type="file"
                            ref={ref}
                            accept=".csv"
                            onChange={(e) => {
                                setCsvData(e.target.files[0]);
                                setFileName(e.target.files[0].name)
                                console.log(e.target.files[0])
                            }}
                        />
                        <div style={{textAlign:"center"}}>
                            <Button onClick={()=>(ref.current.click())}>Select CSV File</Button>

                            <small><br/>&nbsp;{fileName}</small>
                        </div>
                    </div>
                    <small style={{marginTop:"10px", textAlign:"center", width:"100%"}}>
                        Note: The CSV file should contain two mandatory columns, candidate name and email.<br/>
                        Records with invalid emails will be skipped.
                    </small>

                    <br/>
                    <Button onClick={sendTestInvitations} disabled={submitted || !csvData}>Send Test Invitations</Button>
                </FlexBox>
            </FlexBox>
        </ModalBackground>
    )
}

const SendTest = ({candidate, setShow, refresh, submitted, setSubmitted})=>{
    const [tests, setTests] = useState([])
    const [loader, setLoader] = useState(true)
    const [selectedTest, setSelectedTest] = useState("dis")
    const company = useContext(CompanyTeamContext) || {}
    let refreshQuota = company.refresh

    useEffect(()=>{
        axios.get(API_URL_PREFIX+"/GetCompanyLiteTests.html")
            .then(response => {
                setLoader(false)
                if (response.data.err) {
                    CustomErrorToast("Permission Denied!")
                }
                else{
                    if(response.data){
                        let predefined = response.data.filter(e=>e.predefinedTest).sort((b,a) => {
                            return (b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                        })
                        let org = response.data.filter(e=>!(e.predefinedTest)).sort((b,a) => {
                            return (b.name.toLowerCase().localeCompare(a.name.toLowerCase()));
                        })
                        let tests = [...org, ...predefined]
                        setTests(tests);

                        if(tests.length > 0){
                            setSelectedTest(tests[0].key)
                        }
                    }
                }
            })
            .catch(e => {
                setLoader(false);
                if(e.response?.status == 403){
                    CustomErrorToast("Permission Denied!")
                }
                else{
                    CustomErrorToast("Error fetching tests, Please try again.")
                }
            })
    },[])

    return (
        <FlexBox align={"start"} style={{width:"100vw", height:"100vh", position:"fixed", top:0, left:0, zIndex:"1000", background:"rgba(0,0,0,.25)"}}>
            <Loader loader={loader}/>
            <CustomToastContainer/>
            <div className={"hard-shadow"} style={{marginTop:"10%", zIndex:"1001", width:"620px", height:"max-content", background:"white", position:"relative"}}>
                <div style={{textAlign:"left", width:"100%", height:"80px", background:"#4760F1", position:"relative"}}>
                    <FlexBox justify={"start"} align={"center"} style={{
                        lineHeight: "20px", color: "#fff", fontWeight: "800", fontSize: "20px", height:"100%", padding:"20px"
                    }}><span>Send Test to {candidate.firstName}</span>
                        <X size={"25px"} onClick={(e)=>setShow(false)} style={{position:"absolute", right:15, top:15, cursor:"pointer"}}></X>
                    </FlexBox>


                </div>
                <div style={{padding:"20px"}}>
                    {tests && tests.length > 0 ?
                    <select id={"selectTestSelect"} value={selectedTest} onChange={e=>setSelectedTest(e.target.value)} className={"form-select"}>
                        {/*<option disabled={true} value={"dis"}>Select Test</option>*/}
                        {tests.map(e=><option value={e.key}>
                            {e.predefinedTest ?  e.name + " [Predefined]" : e.name}
                        </option>)}
                    </select> : <p>No Tests Found</p>}
                    <br/>
                    <Button id={"sendTestAction"} onClick={e=>sendTestToCandidate(selectedTest, candidate, setShow, refresh, null, refreshQuota, setSubmitted, submitted)} variant={"primary"} disabled={submitted}>Send Test</Button>
                </div>

                <p style={{marginLeft:"20px", display:"flex", alignItems:"center"}}>
                    <InfoCircleFill fill={"rgb(71, 96, 241)"}></InfoCircleFill>
                    &nbsp;<div>Unfinished or Disqualified Tests Will be Refunded to The Sent Tests Quota.</div></p>

            </div>
        </FlexBox>
    )

}

export default SendTest
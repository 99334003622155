import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import React from "react";

export const CustomToastContainer = () => {
    return <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={3}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
}

export const CustomErrorToast = (msg) => {
    // only allow 1 toast at the time
    toast.dismiss()
    // get the toast id
    return toast.error(msg, {
        theme: "light", autoClose: 10000
    })
}

export const CustomSuccessToast = (msg) => {
    // only allow 1 toast at the time
    toast.dismiss()
    toast.success(msg, {theme: "light", autoClose: 5000});
}

export const CustomInfoToast = (msg) => {
    // only allow 1 toast at the time
    toast.dismiss()
    toast.info(msg, {theme: "light", autoClose: 5000});
}

export const updateCustomErrorToast = (msg, id) => {
    // only allow 1 toast at the time
    toast.dismiss()
    //toast.update(id, {render: msg, type: "error", isLoading: false, theme: "light", autoClose: 5000});
    return toast.error(msg, {
        theme: "light", autoClose: 10000
    })
}

export const updateCustomSuccessToast = (msg, id) => {
    // only allow 1 toast at the time
    toast.dismiss()
    //toast.update(id, {render: msg, type: "success", isLoading: false, theme: "light", autoClose: 5000});
    toast.success(msg, {theme: "light", autoClose: 5000});
}

export const CustomLoadingToast = (msg) => {
    // only allow 1 toast at the time
    toast.dismiss()
    // get the toast id
    return toast.loading(msg, {
        theme: "light"
    })
}

export const GetToast = () => {
    // get the toast id
    return toast;
}

export const DismissToast = (id) => {
    toast.dismiss(id);
}

export const DismissAllToasts = () => {
    toast.dismiss();
}


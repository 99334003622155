import React, {useContext, useEffect} from 'react';
import {useParams} from "react-router-dom";
import IFrameWrapper from "../../components/IFrameWrapper";
import {ENTRY_PLAN, IFRAME_DOMAIN} from "../../utilities/Constants";
import CompanyTeamContext from "../../CompanyTeamContext";
import {Button} from "react-bootstrap";

const TestWizard = (props) => {
    const company = useContext(CompanyTeamContext)

    useEffect(() => {
        if(company){
            document.title  = "Test Wizard - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])


    return (
        <>
            <IFrameWrapper showNav={false} width={"1145px"} src={IFRAME_DOMAIN+"/SmartTestsCreator.htm?ck="+encodeURIComponent(company.key)+(company.selectedLitePlan == ENTRY_PLAN ? "&s3=1" : "")} />
        </>
    )
};

export default TestWizard;
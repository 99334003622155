import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import LoginRegister from "../pages/user/login-register/LoginRegister";
import NavBar from "../components/navbar/NavBar";
import Router from "../router/Router";
import SideBar from "../components/side-bar/SideBar";
import FlexBox from "../components/utilities/FlexBox";
import NotFound from "../pages/not-found/NotFound";

import "./Layout.css"
import {createContext, useEffect, useState} from "react";
import axios from "axios";
import {API_URL_PREFIX, APP_URL_PREFIX} from "../utilities/Constants";
import CompanyTeamContext from "../CompanyTeamContext";
import Loader from "../components/utilities/Loader";
import ErrorBoundary from "../components/utilities/ErrorBoundery";
import ActivateUser from "../pages/user/ActivateUser";


const Layout = () => {



    return (<div style={{width: "100%",minWidth:"100vw", height: "100vh", background: "#EFF0FF"}}>
        <BrowserRouter>
            <Routes>
                <Route path={"/user/activate"} element={<ErrorBoundary><ActivateUser/></ErrorBoundary>}/>
                <Route path={"/users/:flow/:token/:ref"} element={<LoginRegister/>}/>
                <Route path={"/users/:flow/:token"} element={<LoginRegister/>}/>
                <Route path={"/users/:flow"} element={<LoginRegister/>}/>
                {/*<Route path={"/TestPage/:name/:key"} element={<ErrorBoundary><TestPage/></ErrorBoundary>}/>*/}
                <Route path={APP_URL_PREFIX+"/*"} element={<MainLayout/>}/>
                <Route path={"/*"} element={<NotFound/>}/>
            </Routes>
        </BrowserRouter>
    </div>)
}

export const UserContext = createContext(null);


const MainLayout = (props) => {
    const [company, setCompany] = useState()
    const [loader, setLoader] = useState(true)
    let navigate = useNavigate()

    // get the connected user details
    useEffect(() => {
        if (!company) {
            refresh()
        }
    }, [])

    const refresh = ()=>{
        // get company context
        axios.get(API_URL_PREFIX+"/GetCompanyContext.html")
            .then(e => {
                setLoader(false)

                if (e.data) {
                    if(e.data.result == false){
                        setCompany(null)
                        if(!(window.location.href.includes("/users/"))){
                            navigate("/users/login")
                        }
                    }
                    else{
                        setCompany({...e.data, refresh, basicTitle:"Technical Hiring Platform - "+e.data.name})
                        document.title = "Technical Hiring Platform - "+e.data.name
                    }
                }
            })
            .catch(err => {
                setCompany(null)
                setLoader(false)
                console.log(err)
            })
    }

    return (<CompanyTeamContext.Provider value={company}>
            <Loader loader={loader}/>
            {!loader && <>

                <FlexBox style={{height: "100%"}}>
                    <SideBar/>
                        <FlexBox style={{height:"100%",width:"100%"}} direction={"column"} justify={"start"} align={"start"}>
                            <NavBar/>
                            <Router/>
                        </FlexBox>

                </FlexBox>
            </>}
    </CompanyTeamContext.Provider>)
}

export default Layout
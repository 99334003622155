import './Billing.css'
import Table from "../../components/utilities/table/Table";
import TableHead from "../../components/utilities/table/TableHead";
import TableBody from "../../components/utilities/table/TableBody";
import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";
import FlexBox from "../../components/utilities/FlexBox";
import {Button} from "react-bootstrap";

import Loader from "../../components/utilities/Loader";
import RoundedLabel from "../../components/utilities/RoundedLabel";
import {
    InfoCircle,
    InfoCircleFill,
    NodeMinus,
    PatchMinus,
    Paypal,
    Plus,
    TelephoneMinus,
    X
} from "react-bootstrap-icons";
import {useContext, useEffect, useMemo, useRef, useState} from "react";
import TableRow from "../../components/utilities/table/TableRow";
import Status from "../../components/quiz-result/Status";
import CompanyTeamContext from "../../CompanyTeamContext";
import companyTeamContext from "../../CompanyTeamContext";
import {PayPalButtons, PayPalScriptProvider} from "@paypal/react-paypal-js";
import {PricingPlan} from "./Pricing";
import {
    ENTERPRISE_PLAN,
    ENTERPRISE_PLAN_TESTS,
    ENTERPRISE_PLAN_MONTHLY,
    ENTERPRISE_PLAN_ANNUAL,
    ENTRY_PLAN,
    ENTRY_PLAN_TESTS,
    ENTRY_PLAN_MONTHLY,
    ENTRY_PLAN_ANNUAL,
    MONTHLY_TIMEFRAME,
    PRO_PLAN,
    PRO_PLAN_TESTS,
    PRO_PLAN_MONTHLY,
    PRO_PLAN_ANNUAL,
    ANNUAL_TIMEFRAME,
    SANDBOX_CLIENT_ID,
    LIVE_CLIENT_ID,
    API_URL_PREFIX
} from "../../utilities/Constants";
import {getPlanDetails, getPlanName, getPlanPaypalId, getPlanPrice, isExpiryInFuture} from "../../utilities/Utilities";
import RadioOptions from "../../components/utilities/radio-options/RadioOptions";
import {
    CustomLoadingToast, CustomSuccessToast, CustomToastContainer, updateCustomErrorToast, updateCustomSuccessToast
} from "../../components/utilities/CustomToast";
import ConfirmModal from "../../components/utilities/ConfirmModal";
import axios from "axios";
import ModalBackground from "../../components/utilities/modal/ModalBackground";
import ModalHeader from "../../components/utilities/modal/ModalHeader";

const QUIZ_PRICE = 17;
const initialOptions = {
    "client-id": LIVE_CLIENT_ID, currency: "USD", vault: true, intent: "subscription", locale: "en_US"
}

const Billing = () => {
    const company = useContext(companyTeamContext) || {}
    const [plan, setPlan] = useState()
    const [timeFrame, setTimeFrame] = useState(ANNUAL_TIMEFRAME)
    /*    if(!company){
            return <Loader loader={true} />
        }*/

    useEffect(() => {
        if(company){
            document.title  = "Billing - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])


    return (<TransparentPageLayout>
        <PayPalScriptProvider options={initialOptions}>


            <FlexBox style={{height: "100%", width: "100%"}} align={"start"}>
                <FlexBox style={{width: "35%", marginRight: "25px", height: "100%"}} direction={"column"}>
                    <Header timeFrame={timeFrame} setTimeFrame={setTimeFrame} company={company}/>
                    <Payment timeFrame={timeFrame} setTimeFrame={setTimeFrame} plan={plan} company={company}/>
                </FlexBox>

                <FlexBox style={{width: "65%", height: "100%"}} justify={"start"} align={"start"}>
                    {company.selectedLitePlan && company.isPlanAutoRenewable ?
                        <PlanWrapper disabled={true} plan={company.selectedLitePlan}
                                     timeFrame={company.selectedLitePlanTimeFrame} setTimeFrame={e => {
                        }} setPlan={e => {
                        }}
                                     company={company}/> :
                        <PlanWrapper plan={plan} timeFrame={timeFrame} setTimeFrame={setTimeFrame} setPlan={setPlan}
                                     company={company}/>}
                </FlexBox>
            </FlexBox>
        </PayPalScriptProvider>
    </TransparentPageLayout>)

}
export default Billing

function Header({timeFrame, setTimeFrame, company}) {


    return (<FlexBox className={"container soft-shadow"} style={{

        width: "100%", height: "80px", padding: "20px", marginBottom: "25px", background: "#fff"
    }}>
        <FlexBox direction={"row"} style={{width: "100%", maxWidth: "1000px"}} justify={"space-between"}>
            <h3 style={{fontWeight: "bold"}}>Plans</h3>
            {company.selectedLitePlan && company.isPlanAutoRenewable ?
                <RadioOptions disabled={true} keys={["ANNUAL", "MONTHLY"]} color={"black"} background={"transparent"}
                              value={company.selectedLitePlanTimeFrame} setValue={e => () => {
                }} options={[{value: ANNUAL_TIMEFRAME, name: "Annually"}, {
                    value: MONTHLY_TIMEFRAME,
                    name: "Monthly"
                }]}/> :
                <RadioOptions keys={["ANNUAL", "MONTHLY"]} color={"black"} background={"transparent"} value={timeFrame}
                              setValue={setTimeFrame} options={[{value: ANNUAL_TIMEFRAME, name: "Annually"}, {
                    value: MONTHLY_TIMEFRAME,
                    name: "Monthly"
                }]}/>}


        </FlexBox>
    </FlexBox>);
}

const PlanWrapper = ({company, plan, setPlan, timeFrame, disabled = false}) => {

    return (<div
        style={{
            width: "100%"
        }}>
        <FlexBox direction={"column"} style={{marginBottom: "10px", height: "25px"}} justify={"start"} align={"start"}>
            <FlexBox align={"start"} style={{position: "relative", width: "100%", height: "650px"}}>

                <>
                    <PricingPlan disabled={disabled} setPlan={setPlan} selectedLitePlan={plan || ""}
                                 timeFrame={timeFrame} refresh={company.refresh || (() => {
                    })}
                                 plan={ENTRY_PLAN}
                                 name={"Entry"}
                                 price={"$" + (timeFrame == ANNUAL_TIMEFRAME ? ENTRY_PLAN_ANNUAL : ENTRY_PLAN_MONTHLY)}
                                 isRecommended={false}
                                 details={getPlanDetails(ENTRY_PLAN, timeFrame)}/>
                    <PricingPlan disabled={disabled} setPlan={setPlan} selectedLitePlan={plan || ""}
                                 timeFrame={timeFrame} refresh={company.refresh || (() => {
                    })} plan={PRO_PLAN} name={"Pro"}
                                 price={"$" + (timeFrame == ANNUAL_TIMEFRAME ? PRO_PLAN_ANNUAL : PRO_PLAN_MONTHLY)}
                                 isRecommended={true}
                                 details={getPlanDetails(PRO_PLAN, timeFrame)}/>
                    <PricingPlan disabled={disabled} setPlan={setPlan} selectedLitePlan={plan || ""}
                                 refresh={company.refresh || (() => {
                                 })} plan={ENTERPRISE_PLAN} name={"Enterprise"}
                                 price={"$" + (timeFrame == ANNUAL_TIMEFRAME ? ENTERPRISE_PLAN_ANNUAL : ENTERPRISE_PLAN_MONTHLY)}
                                 timeFrame={timeFrame} isRecommended={false}
                                 details={getPlanDetails(ENTERPRISE_PLAN, timeFrame)}/>
                </>


            </FlexBox>
        </FlexBox>
    </div>)
}

function Payment({company, plan, timeFrame}) {
    const [subOptions, setSubOptions] = useState({})
    const subRef = useRef()
    const companyRef = useRef();
    const [showSubSettings, setShowSubSettings] = useState(false)
    const currentExpDate = useRef()
    const [upgradeOption, setUpgradeOption] = useState("Immediate")
    const upgradeOptionRef = useRef(upgradeOption)
    //subRef.current = subOptions;
    companyRef.current = company;

    useEffect(()=>{
        upgradeOptionRef.current = upgradeOption;
    },[upgradeOption])

    useEffect(()=>{
        subRef.current = subOptions
    },[subOptions])
    useEffect(() => {
        if (!plan) return;
        // if there is an active plan, then setting the first payment to be after the current expires.
        if (isExpiryInFuture(company) && upgradeOptionRef.current == "Deferred") {
            let expiry = new Date(company.selectedLitePlanExpireDate)
            let newVal = {
                plan_id: getPlanPaypalId(plan, timeFrame),
                custom_id: company.key,
                start_time: expiry.toISOString().slice(0, -5) + 'Z'
            }
            setSubOptions(newVal);
            subRef.current = newVal;
            return;
        }
        // the expiry date already passed or not exist, paying normally
        let newVal = {
            plan_id: getPlanPaypalId(plan, timeFrame), custom_id: company.key
        }
        setSubOptions(newVal)
        subRef.current = newVal;
    }, [company, plan, timeFrame, upgradeOption])

    const isExpiryDateExistsAndInFuture = useMemo(e => isExpiryInFuture(company), [company])

    return (<div className={"container soft-shadow"}
                 style={{

                     width: "100%", padding: "20px", background: "#fff",
                 }}>
        <CustomToastContainer/>
        {showSubSettings && <SubSettingsModal setShow={setShowSubSettings} company={company}/>}
        <FlexBox style={{marginBottom: "10px"}} justify={"space-between"} align={"start"} direction={"column"}>
            <div style={{
                lineHeight: "20px", color: "#979DA5", fontWeight: "800", fontSize: "20px"
            }}>Payment
            </div>
            <br/>
            <div hidden={company?.selectedLitePlan && company?.isPlanAutoRenewable} style={{
                width: "calc(100% + 40px)",
                height: "1px",
                background: "#ebebeb",
                marginRight: "-20px",
                marginLeft: "-20px"
            }}></div>
            <FlexBox justify={"space-between"}
                     style={{width: "100%", borderTop: "1px solid #ebebeb", paddingTop: "10px"}}>
                <div style={{color: "rgb(0, 0, 0)", fontWeight: "500"}}>{getPlanName(plan)}</div>
                <div></div>
                <div hidden={(company?.selectedLitePlan && company?.isPlanAutoRenewable)} style={{
                    color: "#000", width: "92px", textAlign: "right", fontWeight: "500"
                }}>{(!plan ? "$0" : "$" + getPlanPrice(plan, timeFrame))}
                </div>
            </FlexBox>


            <br/>


            <FlexBox direction={"column"} className={"payment-wrapper"} justify={"center"} style={{width: "100%"}}>
                {/*// if there is already a plan then showing the cancel subscription */}
                {company?.selectedLitePlan && company?.isPlanAutoRenewable ? <>
                    <Button style={{width: "320px", marginBottom: "10px"}} variant={"outline-primary"}
                            disabled={!company.isPlanAutoRenewable}
                            onClick={e => setShowSubSettings(true)}>{("Plan Subscription Settings")}</Button>

                </> : <><PayPalButtons style={{zIndex: 5, position: "relative"}}
                                       disabled={!plan}
                                       createSubscription={function (data, actions) {
                                           return actions.subscription.create(subRef.current);
                                       }}
                                       onApprove={(data, actions) => {
                                           const id = CustomLoadingToast("Updating plan...")
                                           if(isExpiryInFuture(company) && upgradeOptionRef.current !== "Deferred"){
                                               currentExpDate.current = companyRef.current.selectedLitePlanExpireDate;
                                           }
                                           // will be upgraded in the future
                                           if(subRef.current.start_time){
                                               updateCustomSuccessToast("Plan will be activated at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()+".", id)
                                           }
                                           else{
                                               const planInterval = setInterval(() => {
                                                   const company = companyRef.current
                                                   // plan activated!
                                                   if (company.isPlanAutoRenewable){
                                                       clearInterval(planInterval)
                                                       updateCustomSuccessToast("Plan Activated!", id)
                                                   } else {
                                                       company.refresh()
                                                   }
                                               }, 1000)
                                           }
                                           console.log(JSON.stringify(data))
                                       }}
                                       onError={function (err) {
                                           // For example, redirect to a specific error page
                                           alert("Error: " + JSON.stringify(err))
                                       }}
                                       onCancel={function (err) {
                                           // For example, redirect to a specific error page
                                           alert("Error: " + JSON.stringify(err))
                                       }}
                />

                    <FlexBox hidden={!isExpiryDateExistsAndInFuture} justify={"space-between"}
                             style={{width: "100%", paddingTop: "10px"}}>
                        
                        <div hidden={(company?.selectedLitePlan && company?.isPlanAutoRenewable)} style={{
                            color: "#000", textAlign: "right", fontWeight: "500"
                        }}>
                        </div>
                    </FlexBox>
                    <div hidden={!isExpiryDateExistsAndInFuture} style={{fontSize: "16px", marginTop:"15px"}}>The payment is immediate, and the change is implemented immediately.
                        <br/>
                    </div>

                </>}
            </FlexBox>
        </FlexBox>

    </div>);
}

const SubSettingsModal = ({setShow,company}) => {
    const [showCancel, setShowCancel] = useState(false)
    const [showUpgradeOptions, setShowUpgradeOptions] = useState(false)

    const cancelSubscription = () => {
        setShowCancel(false)
        const id = CustomLoadingToast("Processing...")
        axios.post(API_URL_PREFIX + "/CancelSubscription.html")
            .then(e => {
                let data = e.data;
                if (data && !data.err) {
                    setShowUpgradeOptions(false)
                    setShow(false)
                    updateCustomSuccessToast("Subscription Canceled", id)

                    if (company.refresh) company.refresh()
                } else {
                    if (data?.info) {
                        updateCustomErrorToast(data.err.info, id)
                    } else {
                        updateCustomErrorToast("Error, Please try again.", id)
                    }
                }
            })
            .catch(err => {
                updateCustomErrorToast("Error, Please try again.", id)
                console.log(err)
            })
    }

    return (

        <ModalBackground style={{position: "fixed"}}>
            {showUpgradeOptions && <UpgradeOptions setShow={setShowUpgradeOptions}/>}
            <FlexBox style={{width: "750px", height: "500px", background: "white", position: "relative"}}
                     className={"hard-shadow"} direction={"column"} justify={"start"}>
                <ModalHeader setShow={setShow} title={"Plan Subscription Settings"}/>
                    <FlexBox style={{width:"100%", height:"100%", padding:"30px"}} direction={"column"} justify={"start"}>
                        <h4 style={{textAlign:"center", width:"100%"}}>{company.selectedLitePlan? getPlanName(company.selectedLitePlan)  + " Plan" : "No Plan Selected"}</h4>
                        {company.selectedLitePlan && company.isPlanAutoRenewable && company.selectedLitePlanExpireDate
                            ?  <h6 style={{textAlign:"center", width:"100%"}}>({"Will be renewed at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()})</h6>
                            : (isExpiryInFuture(company)
                                    ? <h6 style={{textAlign:"center", width:"100%"}}>({"Will expire at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()})</h6>
                                    : (company.selectedLitePlanExpireDate ? <h6 style={{textAlign:"center", width:"100%"}}>({"Expired at "+new Date(company.selectedLitePlanExpireDate).toLocaleDateString()})</h6>
                                        : "")
                            )
                        }
                        <br/><br/>
                        <Button variant={"outline-primary"} style={{width: "320px"}} disabled={!company.isPlanAutoRenewable}
                                onClick={e => setShowUpgradeOptions(true)}><InfoCircle/> {("Plan Change Options")}</Button>
                        <br/>
                        <Button variant={"danger"} style={{width: "320px"}} disabled={!company.isPlanAutoRenewable}
                                onClick={e => setShowCancel(true)}>{("Cancel Subscription")}</Button>
                        <p style={{position:"absolute", bottom:0, padding:"15px", textAlign:"center", fontSize:"14px"}}>&#9679;&nbsp;{"In the case of a subscription cancellation, the '" + getPlanName(company.selectedLitePlan) + " plan' will be active until " + new Date(company.selectedLitePlanExpireDate).toLocaleDateString() + " and won't be renewed."}</p>
                    </FlexBox>
                <ConfirmModal setShow={setShowCancel} show={showCancel} variant={"danger"} title={"Are you sure?"}
                              callback={cancelSubscription} fallback={e => setShowCancel(false)}
                              body={"The " + getPlanName(company.selectedLitePlan) + " plan will be active until " + new Date(company.selectedLitePlanExpireDate).toLocaleDateString() + " and won't be renewed."}/>
            </FlexBox>
        </ModalBackground>

    )
}

const UpgradeOptions = ({setShow}) => {
    return <ModalBackground style={{position: "fixed"}}>
        <FlexBox style={{width: "850px", maxWidth:"100%", height: "max-content", background: "white", position: "relative"}}
                 className={"hard-shadow"} direction={"column"} justify={"start"}>
            <ModalHeader setShow={setShow}  icon={<InfoCircleFill style={{marginRight:"5px"}} />} title={"Plan Change Options"}/>

            <p style={{padding:"15px"}}>
                <b>How to Change plan?</b>
                <ol style={{padding:15, margin:0}}>
                    <li>Use the "Cancel Subscription" button on the "Plan Subscription Settings" page to cancel your current subscription.</li>
                    <li>Select the new plan you wish to change to in the "Billing" page.</li>
                    <li>In the payment section, the change will be affected immediately.</li>
                </ol>


                <p style={{marginBottom:"15px", fontWeight:"bold"}}>Plan Change Options</p>
                <div></div>
                <p>Change your plan immediately to enjoy the enhanced features and benefits. Please note that selecting this option requires canceling your current subscription and selecting a new plan. The payment you have already made for your current subscription cannot be refunded, and you will need to make the payment for the new plan now.</p>


            </p>

        </FlexBox>
    </ModalBackground>
}

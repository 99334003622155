import './Pricing.css'
import FlexBox from "../../components/utilities/FlexBox";
import PageLayout from "../../layout/page-layout/PageLayout";
import {CaretRight, CaretRightFill, Check, Check2All, CheckAll} from "react-bootstrap-icons";
import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";
import {Button} from "react-bootstrap";
import axios from "axios";
import {
    CustomLoadingToast,
    CustomToastContainer,
    updateCustomSuccessToast
} from "../../components/utilities/CustomToast";
import {useContext, useEffect, useState} from "react";
import companyTeamContext from "../../CompanyTeamContext";
import {
    API_URL_PREFIX,
    ENTERPRISE_PLAN_TESTS,
    ENTERPRISE_PLAN_MONTHLY,
    ENTERPRISE_PLAN_ANNUAL,
    ENTRY_PLAN_TESTS,
    MONTHLY_TIMEFRAME,
    PRO_PLAN_TESTS,
    PRO_PLAN_MONTHLY,
    PRO_PLAN_ANNUAL,
    ANNUAL_TIMEFRAME, ENTRY_PLAN, ENTRY_PLAN_ANNUAL, ENTRY_PLAN_MONTHLY, PRO_PLAN, ENTERPRISE_PLAN
} from "../../utilities/Constants";
import RadioOptions from "../../components/utilities/radio-options/RadioOptions";
import {getPlanDetails, getPlanPrice} from "../../utilities/Utilities";


const Pricing = () => {
    const company = useContext(companyTeamContext) || {}
    const [plan, setPlan] = useState("")
    const [timeFrame, setTimeFrame] = useState(ANNUAL_TIMEFRAME)

    useEffect(()=>{
        if(company && company.selectedLitePlan){
            setPlan(company.selectedLitePlan)
        }
        if (company){
            document.title  = "Pricing - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])

    return (<TransparentPageLayout height={"100%"}>
        <Plans plan={plan} timeFrame={timeFrame} setPlan={setPlan} company={company} setTimeFrame={setTimeFrame} />
    </TransparentPageLayout>)

}
export default Pricing

export const Plans = ({plan, setPlan, timeFrame, setTimeFrame, company})=>{
    return (
        <FlexBox direction={"column"} justify={"start"} align={"center"} style={{padding: "0px"}}>
            <FlexBox style={{width:"100%", maxWidth:"1000px"}} justify={"space-between"}>
                <h3 style={{fontWeight:"bold"}}>Plans </h3>
                <RadioOptions color={"black"} background={"transparent"} value={timeFrame} setValue={setTimeFrame} options={[{value:ANNUAL_TIMEFRAME,name:"Annually"},{value:MONTHLY_TIMEFRAME,name:"Monthly"}]} />

            </FlexBox>


            <FlexBox className={"pricing-packages"} justify={"space-between"}  align={"start"}
                     style={{width:"100%", height:"max-content",paddingBottom:"10px", maxWidth:"1000px"}}>
                <PricingPlan noButtons={true} setPlan={setPlan} selectedLitePlan={plan || ""} timeFrame={timeFrame} refresh={company.refresh || (()=>{})} plan={ENTRY_PLAN} img={"https://designstripe-secure.imgix.net/scene-snapshots/a1386346-e80d-4966-8283-e5709d65d81f/1646865992934/default?auto=format&fit=clip&h=850&mark=%2Fwatermark.png&markfit=max&markalign=middle%2Ccenter&markw=1&markh=1&s=d89667529c0a797dfda8936ea1cf7713"} name={"Entry"}
                             price={"$"+(timeFrame == ANNUAL_TIMEFRAME ? ENTRY_PLAN_ANNUAL : ENTRY_PLAN_MONTHLY)} isRecommended={false}
                             details={getPlanDetails(ENTRY_PLAN, timeFrame)} />
                <PricingPlan noButtons={true} setPlan={setPlan} selectedLitePlan={plan || ""} timeFrame={timeFrame} refresh={company.refresh || (()=>{})} plan={PRO_PLAN} img={"https://designstripe-secure.imgix.net/scene-snapshots/3ac1ec86-9c2f-4c40-b258-f75d6c77a660/1652882636395/default?auto=format&fit=clip&h=850&mark=%2Fwatermark.png&markfit=max&markalign=middle%2Ccenter&markw=1&markh=1&s=61a77822a0740ce625d61de515bf0d98"} name={"Pro"}
                             price={"$"+(timeFrame == ANNUAL_TIMEFRAME ? PRO_PLAN_ANNUAL : PRO_PLAN_MONTHLY)} isRecommended={true}
                             details={getPlanDetails(PRO_PLAN, timeFrame)} />
                <PricingPlan noButtons={true} setPlan={setPlan} selectedLitePlan={plan || ""} refresh={company.refresh || (()=>{})} plan={ENTERPRISE_PLAN} img={"https://designstripe-secure.imgix.net/scene-snapshots/ea79f5ef-e39a-4b39-ade8-8398dbd41cb4/1638290538671/default?auto=format&fit=clip&h=850&mark=%2Fwatermark.png&markfit=max&markalign=middle%2Ccenter&markw=1&markh=1&s=25dcf14399d80e336af525e35dc80bbd"} name={"Enterprise"}
                             price={"$"+(timeFrame == ANNUAL_TIMEFRAME ? ENTERPRISE_PLAN_ANNUAL : ENTERPRISE_PLAN_MONTHLY)} timeFrame={timeFrame} isRecommended={false}
                             details={getPlanDetails(ENTERPRISE_PLAN, timeFrame)} />
            </FlexBox>
        </FlexBox>
    )
}

export const PricingPlan = ({  noButtons=false,setPlan ,disabled=false,img,isRecommended=false, name, price, details= [], plan, refresh=(()=>{}),selectedLitePlan, timeFrame, style={}}) => {

    const updatePlan = ()=>{
        if(setPlan) setPlan(plan)
    }
    let selected = (selectedLitePlan.includes(plan));
    if(noButtons) selected = isRecommended


    return(<div
        style={{background:selected ? "#5930D0" : "#fff",fontFamily:"Roboto",borderRadius:"22px",fontFamiliy:"Montserrat"
            ,fontWeight:"bold",flexWrap:"wrap",width:"100%",height:"max-content", border:"1px solid #ebebeb", padding:"16px", margin:"6px",...style}} align={"center"} className={ (disabled && selected) ? "selectedPlan" : ""} >
        <CustomToastContainer />
        <FlexBox direction={"column"} style={{padding:"0px 15px", textAlign:"center", color:selected ? "#fff" : "#000", fontFamily:" 'Montserrat', sans-serif"}}>
        <br/>

        <p style={{fontSize:"20px", marginBottom:"25px"}}>{name}</p>

        <FlexBox align={"start"} style={{height:"5px"}}><span style={{fontSize:"40px", lineHeight:"15px",color:(selected?"":"#5930d0")}}>{price}</span>{<span style={{color:"#bababa", fontSize:"12px", lineHeight:"0px"}}> / {timeFrame == ANNUAL_TIMEFRAME ? "Month": "Month"}</span>}

        </FlexBox>
            {timeFrame == ANNUAL_TIMEFRAME ? <div style={{color:"#bababa",fontWeight:"400",fontSize:"16px"}}><br/>1 Year Subscription</div> : <br/>}
        <br/>

        <div style={{fontWeight:"400", height:"max-content"}}>
            {details.map((e,i)=>{
                return <p key={plan+"desc"+i} style={{width:"100%", textAlign:"start"}}><CheckAll strokeWidth={"0.25px"} stroke={isRecommended ? "#fff" : "#5930D0"} style={{fontWeight:"bold"}} size={"18px"}/>&nbsp;{e}</p>
            })}
        </div>
            {disabled
                ?
                <></>
                :
                <>{selected ? <Button style={{transition:"background 1s",background:(selected?"":"#5930d0"),borderColor:(selected?"":"#5930d0")}} variant={selected ? "light" : "primary"} hidden={noButtons} disabled={true}>Selected</Button> :
                    <Button style={{transition:"background 1s",background:(selected?"":"#5930d0"),borderColor:(selected?"":"#5930d0")}} variant={selected ? "light" : "primary"} hidden={noButtons} onClick={updatePlan}>Select Plan</Button>}</>
            }

            </FlexBox>
    </div>)
}
import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import IFrameWrapper from "../../components/IFrameWrapper";
import {IFRAME_DOMAIN} from "../../utilities/Constants";
import CompanyTeamContext from "../../CompanyTeamContext";
import {Button} from "react-bootstrap";
import axios from "axios";
import {updateCustomErrorToast} from "../../components/utilities/CustomToast";
import Loader from "../../components/utilities/Loader";

const TestWizard = (props) => {
    const company = useContext(CompanyTeamContext)
    const [token, setToken] = useState()

    useEffect(() => {
        if(company){
            document.title  = "Private Questions - " + company.basicTitle

            // get the external auth token
            axios.post("/GenerateExternalAuthToken.html")
                .then(res => {
                    let data = res.data
                    if(data.result === true){
                        setToken(data.token)
                        // refresh the token after expire
                        setTimeout(()=> {
                            window.location.reload()
                        }, 1000*60*60*12)
                    }
                    else if(data.err === true){
                        updateCustomErrorToast(data.info)
                    }
                    else {
                        updateCustomErrorToast("Error authenticating the request")
                    }
                })
                .catch(e => {
                    updateCustomErrorToast("Error authenticating the request")
                })

            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])

    let shortKey = ""
    if(company && company.key){
        shortKey = company.key.substring(0, company.key.length - 3);
    }
    if(!token){
        return <Loader loader={true} />
    }
    return (
        <>
            <IFrameWrapper showNav={false} width={"1345px"}
                           src={IFRAME_DOMAIN
                                   +"/pqmanager.htm?orgId="
                                   +encodeURIComponent(shortKey)
                                   +"&ll=1&orgName="+encodeURIComponent(company?.name)+"&token="+encodeURIComponent(token)
                                }
            />
        </>
    )
};

export default TestWizard;
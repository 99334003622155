import FlexBox from "../utilities/FlexBox";
import search from "../../assets/navbar/Search.svg";
import './SearchBar.css'
import {BarLoader, CircleLoader, ClipLoader} from "react-spinners";
import Loader from "../utilities/Loader";

function SearchBar({style={},refVar,onBlur=()=>{},width="400px",placeHolder="Search", value, setValue, justify="start", loading=false}) {
    return (
        <FlexBox refVar={refVar} justify={justify} className={"search-bar"} style={{width:width}}>
            <div  style={{...style}} className={"search-wrapper"}>
                {loading ? (<ClipLoader size={"15px"} loading={true} />  ) : <img refVar={refVar} src={search} alt={"Search"}/>}

                <input onKeyDown={e=>e.key == 'Escape' && onBlur()} ref={refVar} value={value} onChange={setValue} placeholder={placeHolder} type={"text"}/>
            </div>
        </FlexBox>
    );
}

export default SearchBar
import {
    ENTERPRISE_PLAN,
    ENTERPRISE_PLAN_MONTHLY,
    ENTERPRISE_PLAN_MONTHLY_SUBSCRIPTION_ID,
    ENTERPRISE_PLAN_ANNUAL_SUBSCRIPTION_ID,
    ENTRY_PLAN,
    ENTRY_PLAN_MONTHLY,
    ENTRY_PLAN_MONTHLY_SUBSCRIPTION_ID,
    ENTRY_PLAN_ANNUAL,
    ENTRY_PLAN_ANNUAL_SUBSCRIPTION_ID,
    MONTHLY_TIMEFRAME,
    PRO_PLAN,
    PRO_PLAN_MONTHLY,
    PRO_PLAN_MONTHLY_SUBSCRIPTION_ID,
    PRO_PLAN_ANNUAL,
    PRO_PLAN_ANNUAL_SUBSCRIPTION_ID,
    ANNUAL_TIMEFRAME,
    ENTRY_PLAN_TESTS,
    PRO_PLAN_TESTS,
    ENTERPRISE_PLAN_TESTS
} from "./Constants";

export const getDifficultLevel = (num) => {
    switch (+num) {
        case 2:
            return "Junior";
        case 3:
            return "Senior";
        case 4:
            return "Expert";
    }
}



export const getCandidateFullName = (candidate) => {
    return candidate.firstName + (candidate.middleName ? (` ${candidate.middleName}`) : "") + " " + candidate.lastName;
}

export const checkMobile = (mobile) => {
    return String(mobile)
        .toLowerCase()
        .match(/^[0-9()\-\+]+$/);
}

export const getCompanyInitials = (name) => {
    if (name && name.trim().includes(" ")) {
        let parts = name.trim().split(" ");
        let p1 = parts[0];
        let p2 = parts[1]
        return (p1.charAt(0).toUpperCase() + p2.charAt(0).toUpperCase())
    } else if (name.trim().includes("-")) {
        let parts = name.trim().split("-");
        let p1 = parts[0];
        let p2 = parts[1]
        return (p1.charAt(0).toUpperCase() + p2.charAt(0).toUpperCase())
    } else {
        return (name.charAt(0).toUpperCase())
    }
}


export const getLabelByRole = (num) => {
    switch (+num) {
        case 3:
            return "Company Admin";
        case 2:
            return "Developer";
        case 1:
            return "Recruiter";
        default:
            return "User"
    }
}

export function getUrlParameter(name, url) {
    if (!url) {
        url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getPlanName(plan){
    switch (plan){
        case ENTRY_PLAN:
            return "Entry";
        case PRO_PLAN:
            return "Pro";
        case ENTERPRISE_PLAN:
            return "Enterprise";
    }
}

export function getPlanPrice(plan, timeFrame){
    switch (plan){
        case ENTRY_PLAN:
            return (timeFrame == MONTHLY_TIMEFRAME ? ENTRY_PLAN_MONTHLY : ENTRY_PLAN_ANNUAL);
        case PRO_PLAN:
            return timeFrame == MONTHLY_TIMEFRAME ? PRO_PLAN_MONTHLY : PRO_PLAN_ANNUAL;
        case ENTERPRISE_PLAN:
            return timeFrame == MONTHLY_TIMEFRAME ? ENTERPRISE_PLAN_MONTHLY : ENTRY_PLAN_ANNUAL;
    }
}

export function getPlanPriceWithTaxes(plan, timeFrame){
    switch (plan){
        case ENTRY_PLAN:
            return parseFloat((timeFrame == MONTHLY_TIMEFRAME ? ENTRY_PLAN_MONTHLY : ENTRY_PLAN_ANNUAL)*1.17).toFixed(2);
        case PRO_PLAN:
            return parseFloat((timeFrame == MONTHLY_TIMEFRAME ? PRO_PLAN_MONTHLY : PRO_PLAN_ANNUAL)*1.17).toFixed(2);
        case ENTERPRISE_PLAN:
            return parseFloat((timeFrame == MONTHLY_TIMEFRAME ? ENTERPRISE_PLAN_MONTHLY : ENTRY_PLAN_ANNUAL)*1.17).toFixed(2);
    }
}

export function getPlanPaypalId(plan, timeFrame){
    switch (plan){
        case ENTRY_PLAN:
            return (timeFrame == MONTHLY_TIMEFRAME ? ENTRY_PLAN_MONTHLY_SUBSCRIPTION_ID : ENTRY_PLAN_ANNUAL_SUBSCRIPTION_ID);
        case PRO_PLAN:
            return (timeFrame == MONTHLY_TIMEFRAME ? PRO_PLAN_MONTHLY_SUBSCRIPTION_ID : PRO_PLAN_ANNUAL_SUBSCRIPTION_ID );
        case ENTERPRISE_PLAN:
            return (timeFrame == MONTHLY_TIMEFRAME ? ENTERPRISE_PLAN_MONTHLY_SUBSCRIPTION_ID : ENTERPRISE_PLAN_ANNUAL_SUBSCRIPTION_ID );
    }
}

export function getPlanByPaypalId(id){
    switch (id) {
        case ENTRY_PLAN_ANNUAL_SUBSCRIPTION_ID:
            return [ ENTRY_PLAN, ANNUAL_TIMEFRAME ];
        case PRO_PLAN_ANNUAL_SUBSCRIPTION_ID:
            return [ PRO_PLAN, ANNUAL_TIMEFRAME ];
        case ENTERPRISE_PLAN_ANNUAL_SUBSCRIPTION_ID:
            return [ ENTERPRISE_PLAN, ANNUAL_TIMEFRAME ];
        case ENTRY_PLAN_MONTHLY_SUBSCRIPTION_ID:
            return [ ENTRY_PLAN, MONTHLY_TIMEFRAME ];
        case PRO_PLAN_MONTHLY_SUBSCRIPTION_ID:
            return [ PRO_PLAN, MONTHLY_TIMEFRAME ];
        case ENTERPRISE_PLAN_MONTHLY_SUBSCRIPTION_ID:
            return [ ENTERPRISE_PLAN, MONTHLY_TIMEFRAME ];
        default:
            return [];
    }
}

export const isExpiryInFuture = (company)=>{
    if(company.selectedLitePlanExpireDate){
        // if the expiry date is still in the future
        let now = new Date()
        let expiry = new Date(company.selectedLitePlanExpireDate)
        if(expiry.getTime() > now.getTime()){
            return true;
        }
    }
    return false
}

export const getPlanDetails = (plan, timeframe=MONTHLY_TIMEFRAME) =>{
    let base = ['Full Access to Test Library']
    switch (plan){
        case ENTRY_PLAN:
            base = [...base, (timeframe == MONTHLY_TIMEFRAME ? ENTRY_PLAN_TESTS : ENTRY_PLAN_TESTS*12) +' Test Credits','Chart Results','AI-based proctoring','Coding Challenges','Technical Tests']
            break;
        case PRO_PLAN:
            base = [...base, (timeframe == MONTHLY_TIMEFRAME ? PRO_PLAN_TESTS : PRO_PLAN_TESTS*12) +' Test Credits','Detailed Results','AI-based proctoring','Coding Challenges','Technical Tests','Create your custom tests','Questions Preview']
            break;
        case ENTERPRISE_PLAN:
            base = [...base, (timeframe == MONTHLY_TIMEFRAME ? ENTERPRISE_PLAN_TESTS : ENTERPRISE_PLAN_TESTS*12) +' Test Credits','Detailed Results','AI-based proctoring','Coding Challenges','Technical Tests','Create your custom tests','Questions Preview','Integration with ATS','Training']
            break;
        default:
            break;
    }
    base.push("Customer and candidate support")
    return base;

}

export const convertQueryStringToObject = (queryString)=> {
    let obj = {};

    // Remove the starting '?' if it exists
    if (queryString[0] === '?') {
        queryString = queryString.substr(1);
    }

    const pairs = queryString.split('&');

    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        const key = decodeURIComponent(pair[0]);
        const value = decodeURIComponent(pair[1] || '');

        obj[key] = value;
    }

    return obj;
}

export const generateDescriptionFromQuestions = (questions) => {
    let topicToKey = {};
    try {
        let categoriesQuestionsTypes = new Map();

        for (let question of questions) {

            let categoryName = question.categoryName;
            // private questions
            if (categoryName === "Custom") {
                categoryName = question.topicName;
            }
            if (categoryName === "C Sharp") {
                categoryName = "C#";
            }
            if(!topicToKey[categoryName]){
                topicToKey[categoryName] = question.topicKey;
            }
            if (!categoriesQuestionsTypes.has(categoryName)) {
                categoriesQuestionsTypes.set(categoryName, [0, 0, 0]);
            }
            let types = categoriesQuestionsTypes.get(categoryName);
            if (question.type >= 5) {
                types[0] += 1;
            } else {
                if (question.isOpen) {
                    types[2] += 1;
                } else {
                    types[1] += 1;
                }
            }
        }

        let description = "<div>The test consists of the following:</div><ul>";
        for (let [categoryName, types] of categoriesQuestionsTypes.entries()) {
            let name = categoryName.replaceAll("_", " ");
            description += "<li style=\"overflow-wrap: anywhere\">";
            if (types[0] > 0) {
                description += types[0] + " coding";
                if (types[0] > 1) {
                    description += " questions, ";
                } else {
                    description += " question, ";
                }
            }
            if (types[1] > 0) {
                description += types[1] + " multiple-choice";
                if (types[1] > 1) {
                    description += " questions, ";
                } else {
                    description += " question, ";
                }
            }
            if (types[2] > 0) {
                description += types[2] + " open";
                if (types[2] > 1) {
                    description += " questions, ";
                } else {
                    description += " question, ";
                }
            }
            if (description.endsWith(", ")) {
                description = description.substring(0, description.length - 2);
            }
            description += " in " + name + ".";
            description += "</li>";
        }
        description += "</ul>";
        return description;
    } catch (e) {
        return "";
    }
}

export const b64DecodeUnicode = (str) => {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
}

export const extractContent = (s, space) => {
    var span= document.createElement('span');
    span.innerHTML= s;
    if(space) {
        var children= span.querySelectorAll('*');
        for(var i = 0 ; i < children.length ; i++) {
            if(children[i].textContent)
                children[i].textContent+= ' ';
            else
                children[i].innerText+= ' ';
        }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g,' ');
};
import PropTypes from 'prop-types';


const FlexBox = ({key="",direction = 'row', justify = 'center', align = 'center', children, style, className, hidden=false, onClick=()=>{},refVar}) => {
    if(refVar){
        return (
            <div ref={refVar} key={key} onClick={onClick} hidden={hidden} className={className} style={{display: "flex", flexDirection: direction, justifyContent: justify, alignItems: align, ...style}}>
                {children}
            </div>
        )
    }
    return (
        <div key={key} onClick={onClick} hidden={hidden} className={className} style={{display: "flex", flexDirection: direction, justifyContent: justify, alignItems: align, ...style}}>
            {children}
        </div>
    )
}

FlexBox.propTypes = {
    direction: PropTypes.oneOf(['column', 'row']),
    justify: PropTypes.oneOf(['center', 'start', 'end', 'flex-start', 'flex-end', 'left', 'right','space-between']),
    align: PropTypes.oneOf(['center', 'start', 'end ', 'flex-start', 'flex-end','space-between']),
}

export default FlexBox;
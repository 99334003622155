import './Table.css'

const TableHead = ({columns}) => {
    return (<thead>
    <tr className={"table-head"}
        style={{height: "30px", textAlign: "center", color: "#979DA5", fontWeight: "300", fontSize: "14px"}}>
        {columns.map((col,i) => <th key={col+i}>{col}</th>)}
    </tr>
    </thead>);
}

export default TableHead
import './QuizzesList.css'
import TableHead from "../../../components/utilities/table/TableHead";
import PageLayout from "../../../layout/page-layout/PageLayout";
import Table from "../../../components/utilities/table/Table";
import TableBody from "../../../components/utilities/table/TableBody";
import {useContext, useEffect, useMemo, useState} from "react";
import TableRow from "../../../components/utilities/table/TableRow";
import {Button} from "react-bootstrap";
import FlexBox from "../../../components/utilities/FlexBox";

import SearchBar from "../../../components/search/SearchBar";
import axios from "axios";
import Loader from "../../../components/utilities/Loader";
import {API_URL_PREFIX, APP_URL_PREFIX, PASS_THRESHOLD, TEST_REVIEW_PRIVILEGE} from "../../../utilities/Constants";
import {
    CustomErrorToast,
    CustomLoadingToast,
    CustomToastContainer,
    updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import {CameraFill, Images, PcDisplayHorizontal, PersonFill, WebcamFill, X} from "react-bootstrap-icons";
import ConfirmModal from "../../../components/utilities/ConfirmModal";
import Utilities from "../../user/login-register/Utilities";
import Status from "../../../components/quiz-result/Status";
import CompanyTeamContext from "../../../CompanyTeamContext";
import RadioOptions from "../../../components/utilities/radio-options/RadioOptions";
import {Link, useNavigate, useParams} from "react-router-dom";
import Tooltip from "../../../components/utilities/Tooltip";
import {resendTest} from "../../candidate/candidate-utils/CandidateUtils";
import ModalBackground from "../../../components/utilities/modal/ModalBackground";


const QuizzesList = () => {
    const [quizzes, setQuizzes] = useState([])
    const [loader, setLoader] = useState(true)
    const [keyword, setKeyword] = useState("")
    const [state, setState] = useState("all")
    const {filter} = useParams()
    const [showIframe, setShowIframe] = useState(false)
    const [iframeSrc, setIframeSrc] = useState("")
    const company = useContext(CompanyTeamContext)





    useEffect(()=>{
        setState(filter || "all")
    },[])


    useEffect(()=>{
        if(company){
            document.title  = "Tests Status - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])

    useEffect(() => {
        axios.get(API_URL_PREFIX+"/GetCompanyLiteQuizzes.html")
            .then(response => {
                setLoader(false)
                if (response.data.err) {
                    CustomErrorToast("Permission Denied!")
                } else {
                    setQuizzes(response.data)
                }
            })
            .catch(e => {
                setLoader(false);
                if (e.response.status == 403) {
                    CustomErrorToast("Permission Denied!")
                } else {
                    CustomErrorToast("Error fetching tests, Please try again.")
                }

            })
    }, [])

    const getFilteredList = ()=>{
        let filteredByKeyword = quizzes?.filter(t => {
            return t.testName.toLowerCase().includes(keyword.toLowerCase()) || t.candidateName.toLowerCase().includes(keyword.toLowerCase())
        });
        let filteredByType;
        switch (state){
            case "all":
                filteredByType = filteredByKeyword;
                break;
            case "pending":
                filteredByType = filteredByKeyword.filter(e=>(!e.quizScore && e.quizScore != 0));
                break;
            case "completed":
                filteredByType = filteredByKeyword.filter(e=>(e.quizScore || e.quizScore == 0));
                break;
        }
        return filteredByType;
    }

    const filteredList = useMemo(getFilteredList,[quizzes, filter, keyword, state])

    return (<PageLayout height={"100%"}>
        <CustomToastContainer />
        {showIframe && <IframeModal setShow={setShowIframe} src={iframeSrc} />}
        <Loader loader={loader}/>
        <div style={{justifyContent: "top", display: "flex",height:"100%", width: "100%", flexDirection: "column", padding: "30px"}}>
            <FlexBox style={{width: "100%"}} justify={"space-between"}>
                <h3 style={{color: "#364454", fontWeight: "700", fontSize: "20px"}}>Tests List</h3>
                <SearchBar justify={"end"} value={keyword} setValue={e => setKeyword(e.target.value)}
                           placeHolder={"Search by Test or Candidate Name"}/>
                <RadioOptions color={"#000"} value={state} setValue={setState} options={[{name:"All", value:"all"},{name:"Pending", value:"pending"},{name:"Completed", value:"completed"}]}/>
            </FlexBox>
            <br/>
            <div className={"table-wrapper"}>
                <Table id={"quizzesTable"}>
                    <TableHead columns={["", "Assigned to", "Test Name", "Last Modified",
                        <FlexBox justify={"start"} style={{height:"100%"}}>Score&nbsp;<Tooltip text={"Score out of 100"} /></FlexBox>,
                        <FlexBox justify={"start"} style={{height:"100%"}}>Status&nbsp;<Tooltip text={"Pass score set at 60%"} /></FlexBox>,"Supervised", "",""]}/>
                    <TableBody>
                        {!loader && (!filteredList || filteredList.length == 0)
                            && <tr>
                                <td style={{verticalAlign: "middle", color: "#bababa", textAlign: "center"}} rowSpan={5}
                                    colSpan={6}>No Tests Found.
                                </td>
                            </tr>}
                        {filteredList && filteredList.map((quiz, i) => {
                            return (<QuizRow setShowIframe={setShowIframe} setIframeSrc={setIframeSrc} setQuizzes={setQuizzes} i={i} quiz={quiz}/>)
                        })}
                    </TableBody>
                </Table>

            </div>
            <div style={{marginTop:"10px", fontSize:"12px", textAlign:"left"}}>{!filteredList ? 0 : filteredList.length} Tests</div>
        </div>
    </PageLayout>)
}

export function QuizRow({quiz, setQuizzes, i, setIframeSrc, setShowIframe, showApprovalDate=false}) {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const company = useContext(CompanyTeamContext) || {}
    let refreshQuota = company.refresh
    const [privileges, setPrivileges] = useState([])
    const navigate = useNavigate()
    useEffect(()=>{
        if(company && company.privileges){
            setPrivileges(company.privileges || []);
        }
    },[company])

    const deleteQuiz = () => {

        const id = CustomLoadingToast("Deleting...")
        axios.delete(API_URL_PREFIX+"/DeleteQuizOfCandidate.html?quizId=" + quiz.quizId)
            .then(e => {
                let data = e.data;
                if (data && !data.err) {
                    setQuizzes(quizzes => quizzes.filter(e => e.quizId != quiz.quizId))
                    if(refreshQuota) refreshQuota();
                    updateCustomSuccessToast("Deleted", id)
                } else {
                    updateCustomErrorToast(data.info, id)
                }
                setShowConfirmDelete(false)
            })
            .catch(err => {
                setShowConfirmDelete(false)
                updateCustomErrorToast("Error, Please try again", id)
                console.log(err)
            })
    }
    let score = (quiz.quizScore || quiz.quizScore === 0) ? Math.floor(quiz.quizScore) + "%" : "" ;
    let status=""
    if(quiz.quizScore == -1){
        // TEST DISQUALIFIED - PRINT SCREEN
        if("1"==(quiz.disqualificationStatus)){
            score = <><span style={{color:"red"}}>Disqualified</span> <Tooltip text={"Quiz was Disqualified for \"No Google\""} /></>
        }
        // TEST DISQUALIFIED - NO GOOGLE
        else if("2"==(quiz.disqualificationStatus)){
            score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Quiz was Disqualified for \"Print Screen\""} /></>
        }
        // TEST DISQUALIFIED - Screen Share Cancel
        else if("3"==(quiz.disqualificationStatus)){
            score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Quiz was Disqualified for \"Screen Share was Stopped\""} /></>
        }
     // TEST DISQUALIFIED - ChatGPT
        else if("4"==(quiz.disqualificationStatus)){
            score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Quiz was Disqualified for \"ChatGPT was Used\""} /></>
        }
     // TEST DISQUALIFIED - Gemini 
        else if("5"==(quiz.disqualificationStatus)){
            score = <><span style={{color:"red"}}>Disqualified</span>  <Tooltip text={"Quiz was Disqualified for \"Gemini was Used\""} /></>
        }
        // TEST STOPPED IN THE MIDDLE
        else{
            score = <>Stopped <Tooltip text={"Quiz Was Stopped in The Middle"} /></>
        }


    }
    else{
        status = <Status status={"pending"} />;
        if(quiz.quizScore || quiz.quizScore == 0){
            if(quiz.quizScore > PASS_THRESHOLD){
                status = <Status status={"passed"} />
            }
            else{
                status = <Status status={"failed"} />
            }
        }
    }
    let date = new Date(quiz.quizDate).toLocaleDateString();
    let testStartDate = quiz.quizDate;
    if(quiz.testTime){
        testStartDate = testStartDate - (quiz.testTime*60*1000)
    }
    testStartDate = new Date(testStartDate).toLocaleDateString();
    return <TableRow>
        <td style={{padding: "15px", textAlign: "center"}}>{+i + 1}</td>
        <td><Link className={"candidate-link"}
               to={APP_URL_PREFIX + "/candidate/" + quiz.candidateKey}>{quiz.candidateName}</Link></td>

        <td>{quiz.predefinedTest ? <span style={{fontWeight:"bold"}}>{quiz.testName}</span> : <Link className={"candidate-link"} to={APP_URL_PREFIX+"/test/"+quiz.testKey}>{quiz.testName}</Link>}</td>
        <td style={{textAlign: "left"}}>{date}</td>
        <td>{score}</td>
        <td>{status}</td>
        {showApprovalDate &&  <td>{testStartDate}</td>}
        <td>
            <FlexBox style={{width:"100%"}} justify={"space-between"} align={"center"}>
                {quiz.quizScore !=null && quiz.supportWebcam && <CameraFill style={{cursor:"pointer"}} onClick={e=>{
                  setShowIframe(true)
                  setIframeSrc(`https://www.questions-bank.com/ShowProfileImages.html?liteColor=1&quizId=${encodeURIComponent(quiz.quizId)}&candName=${encodeURIComponent((quiz.candidateName))}`)
                }}

                     title={"View Profile Pictures"} size={"25px"} color={"#125AB3"} />}
                {quiz.didRiskIdentified && <Images onClick={e=>{
                    setShowIframe(true)
                    setIframeSrc(`https://lugotest-hrd.appspot.com/PrintScreenReport.htm?liteColor=1&testKey=${encodeURIComponent(quiz.quizId)}&testName=${encodeURIComponent(quiz.testName)}&applicantName=${encodeURIComponent(quiz.candidateName)}`)
                }} style={{cursor:"pointer"}} size={"24px"} title={"View Screenshots"} color={"#125AB3"} />}
            </FlexBox>

        </td>

        <td>
            <FlexBox style={{cursor: "pointer", position: "relative"}} justify={"end"} align={"center"}>
                <Button style={{width:"115px"}} hidden={quiz.quizScore || quiz.quizScore === 0} onClick={() => {
                    resendTest(quiz.quizId, quiz.candidateKey)
                }} variant={"outline-primary"}>Resend Test</Button>
                {privileges.includes(TEST_REVIEW_PRIVILEGE)
                    ?<>
                        <TestReviewButton quiz={quiz} navigate={navigate}/>
                    </>
                    :
                    ""}
            </FlexBox>
        </td>
        <td>
            <FlexBox style={{cursor: "pointer", position: "relative"}} justify={"end"} align={"center"}>
                <Button hidden={quiz.quizScore || quiz.quizScore === 0} onClick={() => setShowConfirmDelete(true)} style={{width:"115px", textAlign:"center"}} variant={"outline-danger"}><X size={"15px"}/> Delete</Button>
                <TestChartsButton quiz={quiz} navigate={navigate}/>
            </FlexBox>
        </td>

        {showConfirmDelete
            && <ConfirmModal variant={"danger"} show={showConfirmDelete} setShow={setShowConfirmDelete}
                             callback={deleteQuiz} body={"Are you sure you want to delete " + quiz.candidateName + "'s "+quiz.testName+" quiz?"}
                             title={"Confirm Delete"} yes={"Delete"} no={"Cancel"}
                             fallback={e => setShowConfirmDelete(false)}/>}
    </TableRow>;
}

export const IframeModal = ({setShow, src}) => {
    return (<ModalBackground style={{position:"fixed", background:"rgba(0,0,0,.3"}}>
        <FlexBox style={{width:"max-content", height:"max-content", position:"relative"}}>
            <X onClick={e=>setShow(false)} size={"35px"} style={{position:"absolute", top:-35, right:-5, cursor:"pointer"}}></X>
            <iframe style={{border:"1px solid #ccc"}} className={"hard-shadow"} width="1145px" height="700px" src={src} />
        </FlexBox>
    </ModalBackground>)
}

export const TestReviewButton = ({quiz, navigate}) =>{
    return                 <Button   hidden={(!quiz.quizScore && quiz.quizScore !== 0) || quiz.quizScore == -1} onClick={()=>navigate(
        "/TestReview/"+encodeURIComponent(quiz.quizId || quiz.key)
    )}
                                     style={{width:"115px"}} variant={"primary"}>Test Results</Button>
}

export const TestChartsButton = ({quiz, navigate}) =>{

    let timeTaken = quiz.quizTimeTaken
    if(timeTaken !== 0 && !timeTaken){
        timeTaken = quiz.timeTaken
    }

    let score =  Math.floor(quiz.quizScore || 0);

    return <Button   hidden={(!quiz.quizScore && quiz.quizScore !== 0) || quiz.quizScore == -1}
                                 onClick={()=>navigate("/charts/"+encodeURIComponent(

        "?results="+quiz.results+"&score="+score+"&cand="+encodeURIComponent(quiz.candidateName)+"&quizName="+encodeURIComponent(quiz.testName || quiz.name)+
        "&candidateTime="+(timeTaken) +"&totalTime="+(quiz.testTime || quiz.quizTime || 0) +"&enhanced=true"))} style={{width:"115px"}} variant={"success"}>Test Charts</Button>
}

export default QuizzesList
import sanitizeHtml from 'sanitize-html';
import Highlight from "react-highlight";
import './vs2015.css'

const HighlightText = ({input}) => {
    let text = highlightReady(input)
    //return {text}
    return (<Highlight innerHTML={true} className="text-block">
        {text}
    </Highlight>)
}

// add <code> tag for the highlight js and removes the " from the beginning and end of the string
function highlightReady(text) {
    let body = text
    let nMap = sanitizeHtml.defaults.allowedAttributes;
    nMap["span"] = ["style"]
    nMap["em"] = ["style"]
    nMap["pre"] = ['class','spellcheck']
    let allowedSchemes = sanitizeHtml.defaults.allowedSchemes;
    allowedSchemes.push('data')
    body = sanitizeHtml(body, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'code','span','pre','img','em' ])
        ,allowedAttributes : nMap,
        allowedSchemes:allowedSchemes
    });
    if (body[0] === "\"") body = body.substr(1, body.length - 2)
    let pre = /<pre class="ql-syntax" spellcheck="false">/g;
    body = body.replaceAll(pre, "<pre><code>");
    return body.replaceAll(/<\/pre>/g, "</pre></code>");
}

export default HighlightText
import './Table.css'

const TableRow = ({columns = [], children = [], font="16px", style={}}) => {

    return <tr className={"table-row"}
               style={{height: "40px", textAlign: "left", verticalAlign: "middle",fontSize:font,...style}}>
        {columns.map(col => {
            return <td>{col}</td>
        })}
        {children}
    </tr>;
}

export default TableRow
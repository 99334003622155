import {
    CustomErrorToast,
    CustomLoadingToast,
    CustomToastContainer,
    updateCustomErrorToast,
    DismissToast
} from "../../components/utilities/CustomToast";
import loginBackground from "../../assets/login-register/LoginBackground.svg";
import FlexBox from "../../components/utilities/FlexBox";
import LogoImg from "../../assets/newLogo.svg";
import checkEmail from "./login-register/Utilities";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import {getUrlParameter} from "../../utilities/Utilities";

const ActivateUser  = () => {
    return(<FlexBox justify={"space-between"} style={{width: "100vw", height: "100vh"}}>
        <CustomToastContainer/>
        <div style={{
            flexGrow: 3,
            display: "block",
            width: "60%",
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            overflow: "hidden"
        }}>
            <img src={loginBackground} alt={"Login Background"}
                 style={{width: "105%", marginLeft: "-15%", marginTop: "-10%"}}/>
        </div>
         <Content/>

        <a href={"https://lugo-test.com/"} style={{marginBottom: "7px",
            display: "flex",
            justifyContent: "start",
            fontSize: "20px",
            position:"absolute",
            top:15,
            right:0,
            alignItems: "flex-end",
            textDecoration:"none"
        }}
           className={"page "}>
            <img src={LogoImg} alt={"home"} style={{maxWidth: "90%"}}/>Lugo-Test
        </a>
    </FlexBox>)
}

const Content = () => {
    const [companyName, setCompanyName] = useState("")
    return (<div style={{
        flexGrow: 2,
        display: "flex",
        width: "40%",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    }}>
        <div style={{display: "flex", width: "330px", justifyContent: "center", flexDirection: "column"}}>

            {/*            <img src={"https://lugotest-hrd.appspot.com/images/LuGo.png"} alt={"Live Coding"}
                 style={{marginBottom: "30px", maxWidth: "330px"}}/>*/}

            <p><h4 style={{textAlign:"center"}}>You have been invited to join the {companyName} team!</h4></p>
            <p><h6 style={{textAlign:"center"}}>Select your password and activate the account.</h6></p>

            <div>
                <Login  setCompanyName={setCompanyName}/>
            </div>
        </div>
    </div>)
}

const Login = ({ setCompanyName}) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")



    useEffect(()=>{
        const id = CustomLoadingToast("Loading...")
        axios.get("/ActivateUserData.html?tk="+getUrlParameter("tk"))
            .then(res => res.data)
            .then(data => {
                if(data){
                    if(data.err){
                        updateCustomErrorToast(data.err, id)
                    }
                    else {
                        setCompanyName(data.name)
                        setEmail(data.email)
                        DismissToast(id)
                    }
                }
                else{
                    updateCustomErrorToast("Error Activating User", id)
                }
            })
            .catch(e=> {
                updateCustomErrorToast("Error Activating User", id)
            })
    },[])

    const activateUser = () => {

		const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if(!passwordRegex.test(password)){
            updateCustomErrorToast("Password is too weak.")
            return;
        }

        if(!password || password.length < 8){
            CustomErrorToast("Password must have at least 8 characters")
            return;
        }
        if(password != confirmPassword){
            CustomErrorToast("Passwords must match")
            return;
        }


        const id = CustomLoadingToast("Activating...")
        axios.post("/ActivateUser.html", {tk: getUrlParameter("tk"), password: password})
            .then(res => res.data)
            .then(data => {
                if(data.result === true){
                    window.location.href = "https://app.lugo-test.com/users/login?active=1";
                }
                else{
                    updateCustomErrorToast("Error creating user, Please try again.", id)
                }
            })
            .catch(e=>{
                updateCustomErrorToast("Error creating user, Please try again.", id)
            })
    }

    return (<div className={"fadeInElement"}>
        <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
            <label style={{fontSize: "18px"}} className="form-label">Email</label>
            <input disabled={true} id={"email"} onChange={e => {}} value={email} className={"form-control input"}
                   type={"text"}/>
            <br/>
            <label style={{fontSize: "18px"}} className="form-label">Password</label>
            <input id={"password"} onChange={e => setPassword(e.target.value)} value={password} className={"form-control input"}
                   type={"password"}/>
            <br/>
            <label style={{fontSize: "18px"}} className="form-label">Confirm Password</label>
            <input id={"cpassword"} onChange={e => setConfirmPassword(e.target.value)} value={confirmPassword} className={"form-control input"}
                   type={"password"}/>
            <span style={{textAlign:"center",marginBottom: "15px", fontSize: "13px", color: "#000"}}>Your password must be at least 10 characters long, include both upper and lower case letters, and contain at least one special character.</span>
        </FlexBox>
        <br/>
        <br/>
        <button disabled={!confirmPassword} onClick={activateUser} className={"loginButton"}>Activate User
        </button>
        <br/>
    </div>)
}

export default ActivateUser
import './PageLayout.css'

const PageLayout = ({children, height="", padding=""})=>{
    let style = {}
    if(padding) style = {padding:padding};

    return (
        <div style={style} className={"container filled-container"}>
            <div style={{height:height}} className={"content"}>
                {children}
            </div>
        </div>
    )
}

export default PageLayout;
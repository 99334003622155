import React from 'react';
import FlexBox from "./FlexBox";

const RoundedLabel = ({value, style={}}) => {
    return (
        <FlexBox style={{
            borderRadius: "50%",
            background: "#EFEFEF",
            width: "48px",
            height: "48px",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight:"normal"
        ,...style}}>{value}</FlexBox>
    )
};

export default RoundedLabel;
import Loader from "../../components/utilities/Loader";
import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";

import {
    CustomErrorToast, CustomLoadingToast, CustomToastContainer, updateCustomErrorToast, updateCustomSuccessToast
} from "../../components/utilities/CustomToast";

import {Button} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import FlexBox from "../../components/utilities/FlexBox";
import InputText from "../../components/utilities/InputText";
import ReactQuill from "react-quill";
import CompanyTeamContext from "../../CompanyTeamContext";
import {API_URL_PREFIX, APP_URL_PREFIX, DELIMETER_BETWEEN_QUESTIONS} from "../../utilities/Constants";
import axios from "axios";
import ModalBackground from "../../components/utilities/modal/ModalBackground";
import Divider from "../../components/utilities/Divider";
import ModalHeader from "../../components/utilities/modal/ModalHeader";
import DOMPurify from 'dompurify'
const MAX_WIDTH = "1000px"

export const defaultSubjectValue = `{TestName} Quiz Invitation`
export const defaultContentValue = `
    Dear {CandidateName} ,<br/><br/>
    You have been invited to take the {TestName} test.<br/>
    The test has {TimeLimit} time limit.<br/><br/>
    {TestTopics}<br/><br/>
    Click {TestLink} to take the test.<br/><br/>
    Good Luck!<br/>
    {CompanyName} <br/>
`;

const Preferences = () => {
    const [defaultSubject, setDefaultSubject] = useState(false)
    const [subject, setSubject] = useState("")

    const [defaultContent, setDefaultContent] = useState(false)
    const [content, setContent] = useState("")

    const company = useContext(CompanyTeamContext)


    useEffect(() => {
        if(company){
            document.title  = "Preferences - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }

    },[company])

    return (<TransparentPageLayout>
            <div style={{height: "100%"}}>
                <PreferencesHeader/>
                <br/>
                <PreferencesBody defaultContent={defaultContent} setContent={setContent} content={content}
                                 defaultSubject={defaultSubject} setDefaultContent={setDefaultContent}
                                 setDefaultSubject={setDefaultSubject} setSubject={setSubject} subject={subject}
                                 company={company}/>
                <br/>
                
                <CustomToastContainer/>
                <PreferencesSave refresh={company?.refresh || (() => {
                })} subject={subject} defaultSubject={defaultSubject} content={content}
                                 defaultContent={defaultContent}/>
            </div>
        </TransparentPageLayout>)
}

export const PreferencesHeader = () => {
    return (<div className={"container hard-shadow"} style={{
        background: "#4760F1", maxWidth: MAX_WIDTH, padding: "20px"
    }}>
        <FlexBox justify={"space-between"}>
            <div style={{
                lineHeight: "20px", color: "#fff", fontWeight: "800", fontSize: "20px"
            }}>Preferences
            </div>
        </FlexBox>
    </div>);
}


function CustomInviteContentEditor({defaultContent, content, setContent, setDefaultContent}) {
    const [preview, setPreview] = useState(false)

    return <FlexBox style={{width: "100%"}} justify={"center"} align={"start"} direction={"column"}>
        <FlexBox justify={"space-between"} style={{width: "100%", maxWidth: "600px"}} align={"center"}>
            <p>Mail Content</p>
            <Button onClick={()=>setPreview(!preview)}>Preview</Button>
        </FlexBox>
        {preview ? <ContentPreviewModal content={content} setPreview={setPreview}/> : ""}
         <ReactQuill
            style={{width: "100%", maxWidth: "600px", background: defaultContent ? "#ebebeb" : "#fff"}}
            theme={"snow"} readOnly={defaultContent} value={content} onChange={setContent} />
        <small>The invite must contain the following
            tages: <br/>{`{CandidateName}, {TestName}, {TimeLimit}, {TestLink}, {CompanyName}, {TestTopics}`}</small>
        <FlexBox align={"center"} style={{fontSize: "14px"}}><input checked={defaultContent}
                                                                    onChange={e => setDefaultContent(e.target.checked)}

                                                                    type={"checkbox"}/>&nbsp; Default
            Content</FlexBox>
    </FlexBox>;
}

const ContentPreviewModal = ({content, setPreview, companyName}) =>{
    const {name} = useContext(CompanyTeamContext)
    let data = content.replaceAll("<p></p>","<br/>").replaceAll("<p>","<div>").replaceAll("</p>","</div>").
    replaceAll("{CandidateName}", "John Due")
        .replaceAll("{TestName}", "Java Junior")
        .replaceAll("{TimeLimit}",  ("a " + 90 + " minute"))
        .replaceAll("{TestTopics}","The test consists of the following:<br/>" +
            "•    2 coding questions in Angular.<br/>" +
            "•    1 coding question in Java.<br/>" +
            "•    1 coding question in SQL Server.<br/>" +
            "•    1 coding question in Vue.<br/>")
        .replaceAll("{TestLink}", "<a href=\"" + "#" + "\">here</a>")
        .replaceAll("{CompanyName}", name);
    return (
        <ModalBackground style={{position:"fixed"}}>
            <FlexBox direction={"column"} direction={"column"} justify={"start"} align={"start"}
                     style={{width: "450px", height:"maxContent", background: "white"}}>
                <ModalHeader title={"Invite Preview"} setShow={setPreview} />
                <FlexBox direction={"column"} justify={"start"} style={{padding:"15px"}}>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data, { USE_PROFILES: { html: true } })}} />
                </FlexBox>
            </FlexBox>
        </ModalBackground>
    )
}

function CustomInviteSubjectEditor({subject, setSubject, defaultSubject, setDefaultSubject}) {
    return <FlexBox style={{width: "100%"}} justify={"center"} align={"start"} direction={"column"}>
        <p>Mail Subject</p>
        <InputText divWidth={"100%"} style={{maxWidth: "600px"}} width={"100%"} field={subject}
                   setField={e => setSubject(e.target.value)} disabled={defaultSubject}/>
        <FlexBox align={"center"} style={{fontSize: "14px"}}><input checked={defaultSubject}
                                                                    onChange={e => setDefaultSubject(e.target.checked)}
                                                                    type={"checkbox"}/>&nbsp; Default
            Subject</FlexBox>
    </FlexBox>;
}

export const PreferencesBody = ({
                             content,
                             setContent,
                             defaultContent,
                             setDefaultContent,
                             subject,
                             setSubject,
                             defaultSubject,
                             setDefaultSubject,
                             company
                         }) => {
    useEffect(() => {
        if (company && company.customInviteContent?.value) {
            setTimeout(()=>{
                setContent(company.customInviteContent.value)
            },150)
            setDefaultContent(false)
        } else {
            if(company)
                setDefaultContent(true)
        }
        if (company && company.customInviteSubject?.value) {
            setSubject(company.customInviteSubject.value)
            setDefaultSubject(false)
        } else {
            if(company)
                setDefaultSubject(true)
        }
    }, [company])

    useEffect(() => {
        if (defaultSubject) {
            setSubject(defaultSubjectValue)
        }
    }, [defaultSubject])

    useEffect(() => {
        if (defaultContent) {
            setContent(defaultContentValue)
        }
    }, [defaultContent])

    return (<div className={"container soft-shadow"}
                 style={{
                     minHeight: "530px", maxWidth: MAX_WIDTH, width: "100%", padding: "20px", background: "#fff",
                 }}>
        <FlexBox style={{marginBottom: "10px", width: "100%"}} direction={"column"} justify={"start"} align={"start"}>
            <div style={{
                lineHeight: "20px", color: "#979DA5", fontWeight: "800", fontSize: "20px", width: "100%"
            }}>Mail Invitation Preferences
            </div>
            <br/>
            <CustomInviteSubjectEditor subject={subject} setSubject={setSubject} defaultSubject={defaultSubject} setDefaultSubject={setDefaultSubject} />
            <br/>
            <CustomInviteContentEditor defaultContent={defaultContent} content={content} setContent={setContent} setDefaultContent={setDefaultContent} />
            <br/>

        </FlexBox>
    </div>);
}

const PreferencesSave = ({content, defaultContent, defaultSubject, subject, refresh}) => {

    const save = () => {
        const payload = {}
        if (!defaultContent) {
            // make sure all the tags are in the template
            if (!content.includes("{CandidateName}") || !content.includes("{TestTopics}") || !content.includes("{TestName}") || !content.includes("{TimeLimit}") || !content.includes("{TestLink}") || !content.includes("{CompanyName}")) {
                CustomErrorToast('Please make sure all the tags are in the invite')
                return;
            }
            payload["customInviteContent"] =  content;
        }
        else{
            // empty means we will take the default
            payload["customInviteContent"] =  ''
        }

        if(!defaultSubject){
            payload["customInviteSubject"] = subject
        }
        else{
            payload["customInviteSubject"] = ''
        }

        let id = CustomLoadingToast("Processing")
        axios.post(API_URL_PREFIX + "/UpdateInviteTexts.html", payload)
            .then(response => {
                const data = response.data;
                if (data && data.result === true) {
                    updateCustomSuccessToast("Success!", id)
                    refresh();
                } else {
                    updateCustomErrorToast("Error processing test.", id)
                }
            })
            .catch(e => {
                updateCustomErrorToast("Error processing test.", id)
            })
    }
    return (<div className={"container"}
                 style={{
                     maxWidth: MAX_WIDTH, padding: "10px 0px", textAlign: "right"
                 }}>

        <Button onClick={save}
                style={{width: "120px", borderRadius: "0px"}}>Save</Button>
    </div>);
}

export default Preferences
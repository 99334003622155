import './LoginRegister.css'
import {useEffect, useRef, useState} from "react";
import loginBackground from '../../../assets/login-register/LoginBackground.svg'
import axios from "axios";
import {
    CustomErrorToast,
    CustomLoadingToast, CustomSuccessToast,
    CustomToastContainer, DismissToast,
    updateCustomErrorToast,
    updateCustomSuccessToast
} from "../../../components/utilities/CustomToast";
import checkEmail from "./Utilities";
import {Link, useNavigate, useParams} from "react-router-dom";
import FlexBox from "../../../components/utilities/FlexBox";
import { getUrlParameter} from "../../../utilities/Utilities";

import conff from '../../../assets/login-register/confirmation.png'
import {API_URL_PREFIX, APP_URL_PREFIX, FULL_PUBLIC_PREFIX} from "../../../utilities/Constants";
import ModalBackground from "../../../components/utilities/modal/ModalBackground";
import InputText from "../../../components/utilities/InputText";
import Divider from "../../../components/utilities/Divider";
import {Button} from "react-bootstrap";
import Switch from "react-switch";
import LogoImg from '../../../assets/newLogo.svg'

const LoginRegister = () => {
    const params = useParams();
    let flow = "";
    if(params.flow){
        flow = params.flow;
    }
    useEffect(() => {
        document.title = "Login / Register"
    }, [])
    const [reset, setReset] = useState(flow == 'reset')
    const [login, setLogin] = useState(flow == 'login')
    const [confirmation, setConfirmation] = useState(false)

    useEffect(()=>{
        setReset(flow == 'reset')
        setLogin(flow == 'login')
    },[flow])
    return (
        <FlexBox justify={"space-between"} style={{width: "100vw", height: "100vh"}}>
            <CustomToastContainer/>
            <div style={{
                flexGrow: 3,
                display: "block",
                width: "60%",
                height: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                overflow: "hidden"
            }}>
                <img src={loginBackground} alt={"Login Background"}
                     style={{width: "105%", marginLeft: "-15%", marginTop: "-10%"}}/>
            </div>
            {confirmation ? <Confirmation /> : <Content reset={reset} setReset={setReset} setLogin={setLogin} login={login} setConfirmation={setConfirmation}/>}
            <a href={"https://lugo-test.com/"} style={{marginBottom: "7px",
                display: "flex",
                justifyContent: "start",
                fontSize: "20px",
                position:"absolute",
                top:15,
                right:0,
                alignItems: "flex-end",
                textDecoration:"none"
            }}
                 className={"page "}>
                <img src={LogoImg} alt={"home"} style={{maxWidth: "90%"}}/>Lugo-Test
            </a>
        </FlexBox>
    )
}

function Login({setLogin}) {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rememberMe, setRememberMe] = useState(true)
    const [sentActive, setSentActive] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const navigate = useNavigate()
    const [err, setErr] = useState(false)

    useEffect(()=>{
        if(getUrlParameter("active") == 1 && !sentActive){
            CustomSuccessToast("Your Company has been activated successfully!")
            setSentActive(true);
        }
        else if(getUrlParameter("errInfo") && !sentActive){
            CustomErrorToast(getUrlParameter("errInfo"));
            setSentActive(true);
        }
    },[])

    const login = () => {
        setErr(false)
        const id = CustomLoadingToast("Processing")
        let url = API_URL_PREFIX+ "/LoginLiteCompany.html";
        let bodyFormData = new FormData();
        bodyFormData.append('email', email.toLowerCase());
        bodyFormData.append('password', password);

        let data = {email: email, password: password}
        if(getUrlParameter("tk")){
            data["tk"] = getUrlParameter("tk")
        }

        axios.post(url, data)
            .then((res) => res.data)
            .then(res => {
                console.log(res)
                if (res.result === true) {
                    updateCustomSuccessToast("Success!", id)
                    navigate(APP_URL_PREFIX + "/dashboard");
                } else {
                    setErr(true)
                    updateCustomErrorToast(res.info, id)
                }
            })
            .catch(error => {
                updateCustomErrorToast("You have entered an invalid email or password", id)
            })
    }

    return <div className={"fadeInElement"} onKeyDownCapture={e => {
        if (e.key === 'Enter') {
            login()
        }
    }}>
        <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
            <label style={{fontSize: "18px"}} className="form-label">Email</label>
            <input id={"email"} onChange={e => setEmail(e.target.value)} value={email} className={"form-control input"}
                   type={"text"}/>
            <br/>
            <label style={{fontSize: "18px"}} className="form-label">Password</label>
            <input id={"password"} onChange={e => setPassword(e.target.value)} value={password} className={"form-control input"}
                   type={"password"}/>
        </FlexBox>
        <span hidden={!err} id={"errIndicator"}> </span>
        <FlexBox style={{fontWeight: '300', marginTop: "5px"}} justify={"space-between"}>
            <span>
                <input checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} className="form-check-input"
                       type={"checkbox"}/>&nbsp;&nbsp;Remember Me
            </span>
        </FlexBox>
        <br/>
        <br/>
        <button disabled={!checkEmail(email) || password.length < 6} onClick={login} className={"loginButton"}>Login
        </button>
        <br/>
        <small>Don't have an account yet? <Link to={"#"} onClick={e => setLogin(false)}>Sign Up</Link>.</small><br/>
        <small>Forgot your password? Click <Link to={"#"} onClick={e => setForgotPassword(true)}>here</Link> to reset.</small>
        {forgotPassword && <ForgotPassword setShow={setForgotPassword} />}
    </div>
}

const Confirmation = ()=>{
    return (
        <div style={{
            flexGrow: 2,
            display: "flex",
            height: "100%",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center"
        }} className={"confirmation"}>
        <img src={conff} />
        <h4>
            Congratulations! Your company as been created successfully.<br/>
            Please check your registered email for verification and further details.
        </h4>
        </div>
    )
}

const Content = ({login, setLogin, setConfirmation,setReset,reset}) => {
    return (<div style={{
        flexGrow: 2,
        display: "flex",
        width: "40%",
        height: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center"
    }}>
        <div style={{display: "flex", width: "330px", justifyContent: "center", flexDirection: "column"}}>

{/*            <img src={"https://lugotest-hrd.appspot.com/images/LuGo.png"} alt={"Live Coding"}
                 style={{marginBottom: "30px", maxWidth: "330px"}}/>*/}
            <p style={{fontSize:"65px", fontWeight:"1000"}}>Lugo-Test</p>
            <p><h4>Technical Hiring Platform</h4></p>

            <div>
                {reset ? <Reset reset={reset} setReset={setReset} /> : <>
                    {login ?
                        <Login setLogin={setLogin}/> :
                        <Register setConfirmation={setConfirmation} setLogin={setLogin}/> }


                </>}
            </div>
        </div>
    </div>)
}

const Reset = ({setReset,reset}) => {

    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [done, setDone] = useState(false)


    const resetPassword = () => {
        const id = CustomLoadingToast("Processing")

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if(!passwordRegex.test(password)){
            updateCustomErrorToast("Password is too weak.",id)
            return;
        }

        if (!password || password.length < 6) {
            updateCustomErrorToast("Password must contain 8-30 characters.",id)
            return;
        }
        if (password != confirmPassword) {
            updateCustomErrorToast("Passwords do not match.",id)
            return;
        }

        axios.post(API_URL_PREFIX+"/ResetPassword.html", {password, token:getUrlParameter("t")})
            .then(res => {
                let data = res.data
                if (data && data.result == true) {
                    DismissToast(id)
                    setDone(true)
                } else {
                    if (data && data.info) {
                        updateCustomErrorToast(data.info, id)
                    }
                    else {
                        updateCustomErrorToast("Error, Please try again.", id)
                    }
                }
            })
            .catch(error => {
                updateCustomErrorToast("Error, Please try again.", id)
            })
    }
    if(done){
        return (
            <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
                <p style={{fontSize:"18px", textAlign:"center", border:"1px solid #000", padding:"25px 15px", background:"#4A8BD2", color:"white"}}>Your password has been changed.<br/>Click <a style={{color:"white"}} href={"/users/login"}>here</a> to login.</p>

            </FlexBox>
        )
    }

    return (<div onKeyDownCapture={e => {
        if (e.key === 'Enter') {
            resetPassword()
        }
    }}>

        <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
            <h3 style={{textDecoration:"underline", fontWeight:200}}>Reset your password</h3>
            <br/>
            <label style={{fontSize: "18px"}} className="form-label">New Password</label>
            <div className={"afterWrapper"}>
                <input maxLength={30} value={password} onChange={e => {
                    setPassword(e.target.value);
                }} required={true}
                       type={"password"}
                       className={"form-control"}
                       placeholder={""}/>
            </div>
            <br/>
            <label style={{fontSize: "18px"}} className="form-label">Confirm New Password</label>
            <div className={"afterWrapper"}>
                <input maxLength={30} value={confirmPassword} onChange={e => {
                    setConfirmPassword(e.target.value);
                }} required={true}
                       type={"password"}
                       className={"form-control"}
                       placeholder={""}/>
            </div>
            <span style={{textAlign:"center",marginBottom: "15px", fontSize: "13px", color: "#000"}}>Your password must be at least 10 characters long, include both upper and lower case letters, and contain at least one special character.</span>
        </FlexBox>
        <br/>
        <button onClick={resetPassword} className={"loginButton"}>Save Changes</button>
    </div>)
}



const Register = ({setLogin, setConfirmation}) => {
    const [name, setName] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [email, setEmail] = useState("")
    const [status, setStatus] = useState({})
    const [isCompany, setIsCompany] = useState(true)

    const register = () => {
        let stat = {}

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

        if (!password || password.length < 8) {
            stat = ({...stat, p: true})
        }

        if(!passwordRegex.test(password)){
            stat = ({...stat, pe: true})
        }


        if (password != confirmPassword) {
            CustomErrorToast("Passwords do not match")
            stat = ({...stat, cp: true})
        }
        if(isCompany){
            if (!name || name.length < 2) {
                stat = ({...stat, u: "short"})
            }
        }
        if (!email || email.length < 2) {
            stat = ({...stat, e: "short"})
        }
        if (!firstName || firstName.length < 2) {
            stat = ({...stat, fu: "short"})
        }
        if (!lastName || lastName.length < 1) {
            stat = ({...stat, lu: "short"})
        }
        setStatus(stat)
        if (Object.values(stat).find(e => (e === true || e === "short" || e == "taken"))) return;
        const tk = getUrlParameter("tk")
        const referrer = getUrlParameter("ref")
        const id = CustomLoadingToast("Processing")
        let payload = {name: name, password: password, email: email, isCompany:!!isCompany, firstName, lastName};
        if(referrer){
            payload.referrer = referrer;
        }
        if(tk){
            payload.tk = tk;
        }
        axios.post(API_URL_PREFIX+"/RegisterLiteCompany.html", payload)
            .then(res => {
                let data = res.data
                if (data.result == true) {
                    DismissToast(id)
                    setConfirmation(true)
                } else {
                    if (data.info == "email") {
                        setStatus(({...stat, e: "taken"}));
                        updateCustomErrorToast("Email address already exists.", id)
                    } else if (data.info == "invalid") {
                        setStatus(({...stat, e: true}));
                        updateCustomErrorToast("Email address is invalid.", id)
                    }
                    else if (data.info == "no-work-domain") {
                        setStatus(({...stat, e: "no-work-domain"}));
                        updateCustomErrorToast("Email is not associated with known workplace domains.", id)
                    }
                    else {
                        updateCustomErrorToast("Error creating user, Please try again.", id)
                    }
                }
            })
            .catch(error => {
                updateCustomErrorToast("Error creating user, Please try again.", id)
            })
    }


    return (<div className={"fadeInElement"} onKeyDownCapture={e => {
        if (e.key === 'Enter') {
            register()
        }
    }}>




        <FlexBox style={{fontWeight: '300'}} direction={"column"} justify={"left"} align={"start"}>
            <div hidden={!isCompany} style={{width:"100%"}}>
                <label style={{fontSize: "18px", margin:"12px 0px"}} className="form-label">Company Name</label>
                <div className={"afterWrapper" + " " + (status.u == 'short' ? "usernameErr" : "")}>
                    <input maxLength={30} value={name} onChange={e => {
                        setName(e.target.value);
                        if (e.target.value.length > 1) {
                            setStatus({...status, u: null})
                        }
                    }} required={true}
                           type={"text"}
                           className={"form-control" + " input" + " " + (status.u ? "error" : "")}
                           placeholder={""}/>
                </div>
            </div>

            <FlexBox style={{gap:"15px"}}>
                <div>
                    <label style={{fontSize: "18px", margin:"12px 0px"}} className="form-label">First Name</label>
                    <div className={"afterWrapper" + " " + (status.fu == 'short' ? "firstNameErr" : "")}>
                        <input maxLength={30} value={firstName} onChange={e => {
                            setFirstName(e.target.value);
                            if (e.target.value.length > 1) {
                                setStatus({...status, u: null})
                            }
                        }} required={true}
                               type={"text"}
                               className={"form-control" + " input" + " " + (status.fu ? "error" : "")}
                               placeholder={""}/>
                    </div>
                </div>

                <div>
                    <label style={{fontSize: "18px", margin:"12px 0px"}} className="form-label">Last Name</label>
                    <div className={"afterWrapper" + " " + (status.lu == 'short' ? "lastNameErr" : "")}>
                        <input maxLength={30} value={lastName} onChange={e => {
                            setLastName(e.target.value);
                            if (e.target.value.length > 1) {
                                setStatus({...status, u: null})
                            }
                        }} required={true}
                               type={"text"}
                               className={"form-control" + " input" + " " + (status.fu ? "error" : "")}
                               placeholder={""}/>
                    </div>
                </div>

            </FlexBox>



            <label style={{fontSize: "18px", margin:"12px 0px"}} className="form-label">{(isCompany ? "Business " : "")}Email</label>
            <div
                className={"afterWrapper" + " " + (status.e == 'short' ? "shortEmailErr" : "") + (status.e == 'taken' ? "emailErr" : "")+ ((status.e == "no-work-domain" && isCompany) ? "emailWPErr" : "") + " " + (status.e === true ? "emailInvalid" : "") + " " + ((status.e == 'valid' && email) ? "emailValid" : "")}>
                <input maxLength={128}
                       className={"form-control" + " input" + " " + (status.e ? "error" : "")}
                       type={"email"}
                       value={email}
                       onChange={e => setEmail(e.target.value)}
                       required={true}
                       placeholder={"user@"+(isCompany ? "company-domain" : "domain")+".com"}
                />

            </div>
            <small hidden={!isCompany} style={{textAlign:"left", width:"100%", color:"gray"}}>* Must be business/workplace email domain.</small>
            <label style={{fontSize: "18px", margin:"12px 0px"}} className="form-label">Password</label>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%"
            }} className={"afterWrapper" + " " + (status.p ? "passwordErr" : "")+ (status.pe ? " passwordErrRegex" : "")}>
                <input style={{marginBottom: 0}} maxLength={30} value={password} onChange={e => {
                    setPassword(e.target.value);
                    if (e.target.value.length > 1) {
                        setStatus({...status, p: null})
                    }
                }}
                       type={"password"}
                       className={"form-control" + " input" + " " + (status.p ? "error" : "")}
                       required={true}
                       placeholder={""}/>
            </div>
            <label style={{fontSize: "18px", margin:"12px 0px"}} className="form-label">Confirm Password</label>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%"
            }} className={"afterWrapper"}>
                <input style={{marginBottom: 0}} maxLength={30} value={confirmPassword} onChange={e => {
                    setConfirmPassword(e.target.value);
                }}
                       type={"password"}
                       className={"form-control" + " input"}
                       required={true}
                       placeholder={""}/>
                <span style={{textAlign:"center",marginBottom: "15px", fontSize: "13px", color: "#000"}}>Your password must be at least 8 characters long, and include both upper and lower case letters</span>
            </div>
        </FlexBox>
        <BusinessSwitch isCompany={isCompany} setIsCompany={setIsCompany} e={status?.e}/>
        <button onClick={register} className={"loginButton"}>Sign Up</button>
        <p style={{fontSize: "14px"}}>Already have an account? <Link to={"#"} onClick={e => setLogin(true)}
                                                                  style={{color: "blue"}}>Login</Link></p>
    </div>)
}

const BusinessSwitch = ({isCompany, setIsCompany, e}) =>{
    return <>
        <FlexBox style={{marginBottom:"15px"}} justify={"center"} align={"center"}>
            <label style={{marginRight:"10px"}}>Personal</label>
            <Switch offColor={"#77D29C"} checkedIcon={false} uncheckedIcon={false} uncheckedHandleIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#77D29C"
                                                                                                             className="bi bi-file-person-fill" viewBox="0 0 16 16">
                <path
                    d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-1 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm-3 4c2.623 0 4.146.826 5 1.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-1.245C3.854 11.825 5.377 11 8 11z"/>
            </svg>} checkedHandleIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#498CD3"
                                            className="bi bi-buildings-fill" viewBox="0 0 16 16">
                <path
                    d="M15 .5a.5.5 0 0 0-.724-.447l-8 4A.5.5 0 0 0 6 4.5v3.14L.342 9.526A.5.5 0 0 0 0 10v5.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V14h1v1.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V.5ZM2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-1 2v1H2v-1h1Zm1 0h1v1H4v-1Zm9-10v1h-1V3h1ZM8 5h1v1H8V5Zm1 2v1H8V7h1ZM8 9h1v1H8V9Zm2 0h1v1h-1V9Zm-1 2v1H8v-1h1Zm1 0h1v1h-1v-1Zm3-2v1h-1V9h1Zm-1 2h1v1h-1v-1Zm-2-4h1v1h-1V7Zm3 0v1h-1V7h1Zm-2-2v1h-1V5h1Zm1 0h1v1h-1V5Z"/>
            </svg>} onColor={"#498CD3"} onChange={(e)=>{setIsCompany(e)}} checked={isCompany} />
            <label style={{marginLeft:"10px"}}>Business</label>
        </FlexBox>
        <div hidden={!isCompany} style={{textAlign:"center", margin:"15px 0px", fontWeight:"bold", fontSize:"18px", color:"#000"}}>Free tests for business accounts!</div>
        <div style={{textAlign:"center", marginBottom:"10px"}} hidden={e!="no-work-domain"}>For private businesses, contact us at <a href={"mailto:info@lugo-test.com"}>info@lugo-test.com</a> for 2 free demo tests.</div>
    </>
}


const ForgotPassword = ({setShow}) =>{
    const [email, setEmail] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const ref = useRef()


    const mouseOutHandle = e=>{
        setShow(show =>{
            if(show){
                if(ref.current && !ref.current.contains(e.target)){
                    return false;
                }
            }
            return show;
        })
    }

    useEffect(()=>{
        document.addEventListener('mousedown',mouseOutHandle)
        return (()=>{
            document.removeEventListener('mousedown',mouseOutHandle)
        })
    },[])

    const resetPassword = ()=>{
        if(!email || !checkEmail(email)) {
            CustomErrorToast("Invalid Email")
            return;
        }
        const id = CustomLoadingToast("Processing...")
        axios.post(API_URL_PREFIX+"/ResetPasswordRequest.html",{email})
            .then(e=>{
                let data = e.data;
                if(data && !data.err){
                    setSubmitted(true)
                    DismissToast(id)
                }
                else{
                    if(data.info){
                        updateCustomErrorToast(data.info,id)
                    }
                    else{
                        updateCustomErrorToast("Error, please try again.",id)
                    }
                }
            })
            .catch(err=>{
                updateCustomErrorToast("Error, please try again.",id)
                console.log(err)
            })
    }

    return (
        <ModalBackground>

            <FlexBox refVar={ref} direction={"column"} justify={"start"} className={"hard-shadow"}
                     style={{borderRadius:"7px",fontWeight:"400",width:"80%", maxWidth:"300px", background:"white", padding:"15px"}}>
                {submitted ? <p style={{width:"100%", textAlign:"center"}}>Reset instructions has been sent to {email}</p> :
                    <>
                    <h3>Reset Password</h3>
                    <Divider />

                    <InputText marginRight={"0"} width={"100%"} field={email} setField={e=>setEmail(e.target.value)} label={"Email"} required={true} />
                    <br/>
                    <Button onClick={resetPassword} variant={"success"}>Reset Password</Button>
</>
                }
            </FlexBox>

        </ModalBackground>
    )
}

const ResetPassword = () =>{

}


export default LoginRegister


import React, {useEffect} from 'react';
import TransparentPageLayout from "../layout/page-layout/TransparentPageLayout";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

const IFrameWrapper = ({src, width="100%", showNav = true}) => {

    const navigate = useNavigate();

    useEffect(
        ()=>{

            window.addEventListener('message', resize, false);
            return () =>{
                window.removeEventListener('message', resize, false);
            }

        }
        ,[])


    return (<TransparentPageLayout style={{height:"100%"}}>
        <Button hidden={!showNav} onClick={()=> navigate(-1)} variant={"outline-primary"} style={{position:"absolute", top:0,right:0}}>Back</Button>
        <iframe id={"iframe"} className={"iframe"} src={src} height={"100%"} style={{width: width}}/>
    </TransparentPageLayout>)

};

function resize(e) {
    let iframe = document.getElementById("iframe")
    let eventName = e.data[0];
    let data = e.data[1];
    switch(eventName) {
        case 'setHeight':
            iframe.height = data
            break;
    }
}

export default IFrameWrapper;

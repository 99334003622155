import './Invoices.css'
import FlexBox from "../../components/utilities/FlexBox";
import TransparentPageLayout from "../../layout/page-layout/TransparentPageLayout";
import {useContext, useEffect, useState} from "react";
import companyTeamContext from "../../CompanyTeamContext";
import TableBody from "../../components/utilities/table/TableBody";
import {Table} from "react-bootstrap";
import TableRow from "../../components/utilities/table/TableRow";
import Status from "../../components/quiz-result/Status";
import axios from "axios";
import {API_URL_PREFIX} from "../../utilities/Constants";
import {CustomErrorToast, updateCustomErrorToast} from "../../components/utilities/CustomToast";
import Loader from "../../components/utilities/Loader";
import {getPlanByPaypalId} from "../../utilities/Utilities";
import TableHead from "../../components/utilities/table/TableHead";



const Invoices = () => {
    const company = useContext(companyTeamContext) || {}
    const [invoices, setInvoices] = useState([])
    const [loaded, setLoaded] = useState(false)


    useEffect(()=>{
        if(company){
            document.title  = "Invoices - " + company.basicTitle
            return () => {
                document.title = company.basicTitle
            }
        }
    },[company])

    useEffect(()=>{
        axios.get(API_URL_PREFIX+"/GetCompanyLiteInvoices.html")
            .then(e=>{
                setLoaded(true)
                let data = e.data;
                if(data && !data.err){
                    setInvoices(data)
                }
                else{
                    if(data.info)
                        CustomErrorToast(data.info)
                    else{
                        CustomErrorToast("Error fetching invoices.")
                    }
                }
            })
            .catch(err=>{
                setLoaded(true)
                console.log(err)
                CustomErrorToast("Error getting invoices.")
            })
    },[company])

    return (<TransparentPageLayout height={"100%"}>
        <FlexBox direction={"column"} justify={"start"} align={"center"} style={{padding: "0px"}}>
            <Loader loader={!loaded} />
            {loaded && <InvoicesTable invoices={invoices} />}
        </FlexBox>
    </TransparentPageLayout>)

}

function InvoicesTable({invoices}) {
    return (<div className={"container soft-shadow"}
                 style={{
                     height: "675px", width: "100%", padding: "20px", background: "#fff"
                 }}>
        <FlexBox style={{marginBottom: "10px", height: "25px"}} justify={"start"} align={"start"}>
            <div style={{
                lineHeight: "20px", color: "#979DA5", fontWeight: "800", fontSize: "20px"
            }}>Invoices
            </div>

        </FlexBox>
        <div style={{overflowY: "auto", height: "610px"}}>
            <Table style={{}}>
                <TableHead columns={["Plan","Date","Amount","Method",""]} />
                <TableBody style={{textAlign: "center"}}>
                    {invoices?.map(invoice =>{
                        return <Invoice invoice={invoice}/>
                    })}
                </TableBody>
            </Table>
        </div>
    </div>);
}

const Invoice = ({invoice}) => {
    return (<TableRow style={{textAlign: "left", color: "#636E7B", height: "55px", fontWeight:"bold"}}>
        <td style={{color: "rgb(71, 96, 241)", fontWeight: "bold"}}>{getPlanByPaypalId(invoice.planId).join("_").replaceAll("_"," ")}</td>
        <td>{new Date(invoice.date).toLocaleDateString()}</td>
        <td>{invoice.price}</td>
        <td>Paypal</td>
        <td><Status label={"SUCCESS"} status={"passed"} style={{margin: "auto"}}/></td>
    </TableRow>)
}

export default Invoices
